
<style lang="less">
    .cursor-pointer {
        cursor: pointer;
    }
    .main-body {
        padding-top: 45px;
        margin-left: 20%;
        margin-right: 20%;
    }
    .title-label {
        font-size: 3rem;
    }
    .scorecard-cell {
        height: 5rem;
    }
    .scorecard-font-size {
        font-size: 3rem;
    }
    .putt-btn {
        height: 7rem;
        font-size: 4rem;
        color: #666666;
    }
    .margin-top-7 {
        margin-top: 7rem !important;
    }
    .margin-top-6 {
        margin-top: 5rem !important;
    }
    .background-blue {
        background-color: #87cefa;
        color: white;
    }
    .background-green {
        background-color: forestgreen;
        color: white;
    }
    .background-red {
        background-color: red;
        color: white;
    }
    .red-text {
        color: red;
    }
    .green-text {
        color: forestgreen;
    }
    .light-blue-text {
        color: #87cefa;
    }
    .blue-text {
        color: blue;
    }
    .dark-grey-text {
        color: #333333;
    }
    .light-grey-text {
        color: #999999;
    }
    .fast-spin {
      -webkit-animation: fa-spin .7s infinite linear;
      animation: fa-spin .7s infinite linear;
    }
</style>

<template>
    <div >
        <div v-if="show_fans_watching">
            <b-row class="ml-3  mr-3 mt-3">
                <b-col cols="1">
                    <i class="fal fa-chevron-left fa-4x light-grey-text mt-3" @click="close_show_fans_watching()"></i>
                </b-col>
                 <b-col cols="10" class="title-label text-center">
                     <div v-if="!game.is_game_complete">Fans Watching ({{fans_watching_list.length}})</div>
                     <div v-if="game.is_game_complete">Fans Who Watched ({{fans_watching_list.length}})</div>
                     <div v-if="game.type === 1" class="h3">Putting Match Play</div>
                     <div class="h3">{{formatDate(game.start)}}</div>
                 </b-col>
                <b-col cols="1">
                </b-col>
            </b-row>
            <div v-for="game_broadcast_contact in fans_watching_list" class="ml-5 mr-5 border mb-4 mt-5">
                 <b-row>
                    <b-col cols="3" class="text-center name-initials-box">
                        <div class="pt-2">
                            {{game_broadcast_contact.broadcast_contact.name_initials}}
                        </div>
                    </b-col>
                    <b-col cols="9">
                        <div class="contact-name mt-3 ml-3">
                            {{game_broadcast_contact.broadcast_contact.fullname}}
                        </div>
                        <div class="contact-mobile mb-4 ml-3">
                            {{game_broadcast_contact.broadcast_contact.mobile_display}}
                        </div>
                    </b-col>
                </b-row>
             </div>
        </div>
        <div v-if="!show_fans_watching">
            <b-row class="ml-3 mt-3">
            <b-col cols="2">
                <i class="fas fa-analytics fa-4x light-grey-text" @click="go_stats()"></i>
            </b-col>
            <b-col cols="8" class="title-label text-center">
                <div v-if="game.player">
                    <div>{{game.player.first_name}} vs {{game.opponent.fullname}}</div>
                    <div v-if="game.type === 1" class="h3">Putting Match Play</div>
                    <div v-if="game.type === 2" class="h3">Up & Down Match Play</div>
                    <div class="h3">{{formatDate(game.start)}}</div>
                </div>
            </b-col>
            <b-col cols="2">
                <div class="float-right" @click="go_show_fans_watching()">
                    <i class="fad fa-users fa-4x light-grey-text mt-3 mr-3"></i>
                </div>
            </b-col>

        </b-row>
            <b-row class="ml-3 mr-3 mt-5 text-center" v-visibility-change="visibilityChanged">
            <b-col class="border scorecard-cell" :class="{'background-blue':current_hole === 1,'background-green':current_hole > 1 && game.score_1 > 0,'background-red':current_hole > 1 && game.score_1 < 0}">
                <span v-if="current_hole > 1" class="scorecard-font-size">
                    <span v-if="game.score_1 > 0">{{game.score_1}}<i class="fas fa-caret-up"></i></span>
                    <span v-else-if="game.score_1 < 0">{{convert_to_pos_number(game.score_1)}}<i class="fas fa-caret-down"></i></span>
                    <span v-else>AS</span>
                </span>
                <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 1 }">1</span>
            </b-col>
            <b-col class="border-top border-bottom scorecard-cell" :class="{'background-blue':current_hole === 2,'background-green':current_hole > 2 && game.score_2 > 0,'background-red':current_hole > 2 && game.score_2 < 0}">
                <span v-if="current_hole > 2" class="scorecard-font-size">
                    <span v-if="game.score_2 > 0">{{game.score_2}}<i class="fas fa-caret-up"></i></span>
                    <span v-else-if="game.score_2 < 0">{{convert_to_pos_number(game.score_2)}}<i class="fas fa-caret-down"></i></span>
                    <span v-else class="dark-grey-text">AS</span>
                </span>
                <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 2 }">2</span>
            </b-col>
            <b-col class="border-top border-bottom scorecard-cell" :class="{'background-blue':current_hole === 3,'background-green':current_hole > 3 && game.score_3 > 0,'background-red':current_hole > 3 && game.score_3 < 0}">
                <span v-if="current_hole > 3" class="scorecard-font-size">
                    <span v-if="game.score_3 > 0">{{game.score_3}}<i class="fas fa-caret-up"></i></span>
                    <span v-else-if="game.score_3 < 0">{{convert_to_pos_number(game.score_3)}}<i class="fas fa-caret-down"></i></span>
                    <span v-else>AS</span>
                </span>
                <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 3 }">3</span>
            </b-col>
            <b-col class="border scorecard-cell" :class="{'background-blue':current_hole === 4,'background-green':current_hole > 4 && game.score_4 > 0,'background-red':current_hole > 4 && game.score_4 < 0}">
                <span v-if="current_hole > 4" class="scorecard-font-size">
                    <span v-if="game.score_4 > 0">{{game.score_4}}<i class="fas fa-caret-up"></i></span>
                    <span v-else-if="game.score_4 < 0">{{convert_to_pos_number(game.score_4)}}<i class="fas fa-caret-down"></i></span>
                    <span v-else>AS</span>
                </span>
                <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 4 }">4</span>
            </b-col>
            <b-col class="border-top border-bottom scorecard-cell" :class="{'background-blue':current_hole === 5,'background-green':current_hole > 5 && game.score_5 > 0,'background-red':current_hole > 5 && game.score_5 < 0}">
                <span v-if="current_hole > 5" class="scorecard-font-size">
                    <span v-if="game.score_5 > 0">{{game.score_5}}<i class="fas fa-caret-up"></i></span>
                    <span v-else-if="game.score_5 < 0">{{convert_to_pos_number(game.score_5)}}<i class="fas fa-caret-down"></i></span>
                    <span v-else>AS</span>
                </span>
                <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 5 }">5</span>
            </b-col>
            <b-col class="border scorecard-cell" :class="{'background-blue':current_hole === 6,'background-green':current_hole > 6 && game.score_6 > 0,'background-red':current_hole > 6 && game.score_6 < 0}">
                <span v-if="current_hole > 6" class="scorecard-font-size">
                    <span v-if="game.score_6 > 0">{{game.score_6}}<i class="fas fa-caret-up"></i></span>
                    <span v-else-if="game.score_6 < 0">{{convert_to_pos_number(game.score_6)}}<i class="fas fa-caret-down"></i></span>
                    <span v-else>AS</span>
                </span>
                <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 6 }">6</span>
            </b-col>
            <b-col class="border-top border-bottom scorecard-cell" :class="{'background-blue':current_hole === 7,'background-green':current_hole > 7 && game.score_7 > 0,'background-red':current_hole > 7 && game.score_7 < 0}">
                <span v-if="current_hole > 7" class="scorecard-font-size">
                    <span v-if="game.score_7 > 0">{{game.score_7}}<i class="fas fa-caret-up"></i></span>
                    <span v-else-if="game.score_7 < 0">{{convert_to_pos_number(game.score_7)}}<i class="fas fa-caret-down"></i></span>
                    <span v-else>AS</span>
                </span>
                <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 7 }">7</span>
            </b-col>
            <b-col class="border scorecard-cell" :class="{'background-blue':current_hole === 8,'background-green':current_hole > 8 && game.score_8 > 0,'background-red':current_hole > 8 && game.score_8 < 0}">
                <span v-if="current_hole > 8" class="scorecard-font-size">
                    <span v-if="game.score_8 > 0">{{game.score_8}}<i class="fas fa-caret-up"></i></span>
                    <span v-else-if="game.score_8 < 0">{{convert_to_pos_number(game.score_8)}}<i class="fas fa-caret-down"></i></span>
                    <span v-else>AS</span>
                </span>
                <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 8 }">8</span>
            </b-col>
            <b-col class="border-top border-bottom border-right scorecard-cell" :class="{'background-blue':current_hole === 9,'background-green':current_hole > 9 && game.score_9 > 0,'background-red':current_hole > 9 && game.score_9 < 0}">
                <span v-if="current_hole > 9" class="scorecard-font-size">
                    <span v-if="game.score_9 > 0">{{game.score_9}}<i class="fas fa-caret-up"></i></span>
                    <span v-else-if="game.score_9 < 0">{{convert_to_pos_number(game.score_9)}}<i class="fas fa-caret-down"></i></span>
                    <span v-else>AS</span>
                </span>
                <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 9 }">9</span>
            </b-col>
        </b-row>
            <b-row v-if="game.game_holes === 18" class="ml-3 mr-3 text-center">
            <b-col class="border scorecard-cell" :class="{'background-blue':current_hole === 10,'background-green':current_hole > 10 && game.score_10 > 0,'background-red':current_hole > 10 && game.score_10 < 0}">
                <span v-if="current_hole > 10" class="scorecard-font-size">
                    <span v-if="game.score_10 > 0">{{game.score_10}}<i class="fas fa-caret-up"></i></span>
                    <span v-else-if="game.score_10 < 0">{{convert_to_pos_number(game.score_10)}}<i class="fas fa-caret-down"></i></span>
                    <span v-else>AS</span>
                </span>
                <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 10 }">10</span>
            </b-col>
            <b-col class="border-top border-bottom scorecard-cell" :class="{'background-blue':current_hole === 11,'background-green':current_hole > 11 && game.score_11 > 0,'background-red':current_hole > 11 && game.score_11 < 0}">
                <span v-if="current_hole > 11" class="scorecard-font-size">
                    <span v-if="game.score_11 > 0">{{game.score_11}}<i class="fas fa-caret-up"></i></span>
                    <span v-else-if="game.score_11 < 0">{{convert_to_pos_number(game.score_11)}}<i class="fas fa-caret-down"></i></span>
                    <span v-else class="dark-grey-text">AS</span>
                </span>
                <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 11 }">11</span>
            </b-col>
            <b-col class="border-top border-bottom scorecard-cell" :class="{'background-blue':current_hole === 12,'background-green':current_hole > 12 && game.score_12 > 0,'background-red':current_hole > 12 && game.score_12 < 0}">
                <span v-if="current_hole > 12" class="scorecard-font-size">
                    <span v-if="game.score_12 > 0">{{game.score_12}}<i class="fas fa-caret-up"></i></span>
                    <span v-else-if="game.score_12 < 0">{{convert_to_pos_number(game.score_12)}}<i class="fas fa-caret-down"></i></span>
                    <span v-else>AS</span>
                </span>
                <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 12 }">12</span>
            </b-col>
            <b-col class="border scorecard-cell" :class="{'background-blue':current_hole === 13,'background-green':current_hole > 13 && game.score_13 > 0,'background-red':current_hole > 13 && game.score_13 < 0}">
                <span v-if="current_hole > 13" class="scorecard-font-size">
                    <span v-if="game.score_13 > 0">{{game.score_13}}<i class="fas fa-caret-up"></i></span>
                    <span v-else-if="game.score_13 < 0">{{convert_to_pos_number(game.score_13)}}<i class="fas fa-caret-down"></i></span>
                    <span v-else>AS</span>
                </span>
                <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 13 }">13</span>
            </b-col>
            <b-col class="border-top border-bottom scorecard-cell" :class="{'background-blue':current_hole === 14,'background-green':current_hole > 14 && game.score_14 > 0,'background-red':current_hole > 14 && game.score_14 < 0}">
                <span v-if="current_hole > 14" class="scorecard-font-size">
                    <span v-if="game.score_14 > 0">{{game.score_14}}<i class="fas fa-caret-up"></i></span>
                    <span v-else-if="game.score_14 < 0">{{convert_to_pos_number(game.score_14)}}<i class="fas fa-caret-down"></i></span>
                    <span v-else>AS</span>
                </span>
                <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 14 }">14</span>
            </b-col>
            <b-col class="border scorecard-cell" :class="{'background-blue':current_hole === 15,'background-green':current_hole > 15 && game.score_15 > 0,'background-red':current_hole > 15 && game.score_15 < 0}">
                <span v-if="current_hole > 15" class="scorecard-font-size">
                    <span v-if="game.score_15 > 0">{{game.score_15}}<i class="fas fa-caret-up"></i></span>
                    <span v-else-if="game.score_15 < 0">{{convert_to_pos_number(game.score_15)}}<i class="fas fa-caret-down"></i></span>
                    <span v-else>AS</span>
                </span>
                <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 15 }">15</span>
            </b-col>
            <b-col class="border-top border-bottom scorecard-cell" :class="{'background-blue':current_hole === 16,'background-green':current_hole > 16 && game.score_16 > 0,'background-red':current_hole > 16 && game.score_16 < 0}">
                <span v-if="current_hole > 16" class="scorecard-font-size">
                    <span v-if="game.score_16 > 0">{{game.score_16}}<i class="fas fa-caret-up"></i></span>
                    <span v-else-if="game.score_16 < 0">{{convert_to_pos_number(game.score_16)}}<i class="fas fa-caret-down"></i></span>
                    <span v-else>AS</span>
                </span>
                <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 16 }">16</span>
            </b-col>
            <b-col class="border scorecard-cell" :class="{'background-blue':current_hole === 17,'background-green':current_hole > 17 && game.score_17 > 0,'background-red':current_hole > 17 && game.score_17 < 0}">
                <span v-if="current_hole > 17" class="scorecard-font-size">
                    <span v-if="game.score_17 > 0">{{game.score_17}}<i class="fas fa-caret-up"></i></span>
                    <span v-else-if="game.score_17 < 0">{{convert_to_pos_number(game.score_17)}}<i class="fas fa-caret-down"></i></span>
                    <span v-else>AS</span>
                </span>
                <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 17 }">17</span>
            </b-col>
            <b-col class="border-top border-bottom border-right scorecard-cell" :class="{'background-blue':current_hole === 18,'background-green':current_hole > 18 && game.score_18 > 0,'background-red':current_hole > 18 && game.score_18 < 0}">
                <span v-if="current_hole > 18" class="scorecard-font-size">
                    <span v-if="game.score_18 > 0">{{game.score_18}}<i class="fas fa-caret-up"></i></span>
                    <span v-else-if="game.score_18 < 0">{{convert_to_pos_number(game.score_18)}}<i class="fas fa-caret-down"></i></span>
                    <span v-else>AS</span>
                </span>
                <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 18 }">18</span>
            </b-col>
        </b-row>

            <div class="title-label text-center mt-5 mb-5">{{game.score_text_broadcast}}</div>

            <div v-if="!game.is_game_complete">
                <b-row class="ml-3 mr-3 border mb-3">
                    <b-col cols="2" class="text-center">
                        <b-spinner class="blue-color margin-top-6" style="width: 3rem; height: 3rem;" type="grow"></b-spinner>
                    </b-col>
                    <b-col v-if="game.type === 1" cols="10">
                        <b-row class="h1"><strong>Hole {{current_hole}}</strong></b-row>
                        <b-row class="h1">{{game.putt_length}} Foot {{game.putt_slope_title}} Putt</b-row>
                        <b-row class="h1">{{game.opponent.fullname}} {{game.pro_putt_number_current_hole}}-Putted</b-row>
                        <b-row class="h2 blue-text font-italic" v-if="game.pro_putt_number_current_hole > 0">{{game.player.first_name}} is putting..</b-row>
                    </b-col>
                    <b-col v-if="game.type === 2" cols="10">
                        <b-row class="h1"><strong>Hole {{current_hole}}</strong></b-row>
                        <b-row class="h1">{{game.up_yardage_title}}</b-row>
                        <b-row class="h1" v-if="game.pro_putt_number_current_hole > 2">Tiger {{game.pro_putt_number_current_hole}} Strokes</b-row>
                        <b-row class="h1" v-if="game.pro_putt_number_current_hole === 2">Tiger {{game.pro_putt_number_current_hole}} Strokes <b-badge variant="success">Up & Down</b-badge></span></b-row>
                        <b-row class="h2 blue-text font-italic" v-if="game.pro_putt_number_current_hole > 0">{{game.player.first_name}} is playing..</b-row>
                    </b-col>
                </b-row>
            </div>

            <div v-for="hole in hole_summary_list" >
                <b-row class="ml-3 mr-3 border mb-3">
                    <b-col cols="2" class="text-center">
                        <i v-if="hole.hole_score > 0" class="fas fa-caret-up fa-10x mt-3 green-text"></i>
                        <i v-else-if="hole.hole_score === 0" class="far fa-equals fa-5x margin-top-6 light-grey-text"></i>
                        <i v-else class="fas fa-caret-down fa-10x mt-3 red-text"></i>
                    </b-col>
                    <b-col cols="10">
                        <b-row class="h1"><strong>Hole {{hole.hole_number}}</strong></b-row>
                        <b-row class="h1">{{hole.hole_description}}</b-row>
                        <b-row v-if="game.type === 1" class="h1"><span :class="{'green-text': hole.opponent_strokes === 1}">{{game.opponent.fullname}}&nbsp;{{hole.opponent_strokes}}-Putted</span></b-row>
                        <b-row v-if="game.type === 1" class="h1"><span :class="{'green-text': hole.your_strokes === 1}">{{game.player.first_name}}&nbsp;{{hole.your_strokes}}-Putted</span></b-row>
                        <b-row v-if="game.type === 2" class="h1">Tiger {{hole.opponent_strokes}} Strokes <span v-if="hole.opponent_strokes === 2" class="ml-3 green-text"> <b-badge variant="success">Up & Down</b-badge></span></b-row>
                        <b-row v-if="game.type === 2" class="h1">{{game.player.first_name}} {{hole.your_strokes}} Strokes <span v-if="hole.your_strokes === 2" class="ml-3 green-text"> <b-badge variant="success">Up & Down</b-badge></span></b-row>
                    </b-col>
                </b-row>
            </div>
        </div>
        </div>

    </div>
</template>

<script>
    import _ from "underscore";

    export default {
        name: "GameBroadcast",
        data() {
            return {
                GameRes: this.$resource('/api/game{/token}/'),
                BroadcastContactRes: this.$resource('/api/broadcastcontact{/token}/'),
                GameBroadcastContactRes: this.$resource('/api/gamebroadcastcontact{/token}/'),
                game: {},
                current_hole: 0,
                current_putt_length: 0,
                current_putt_slope: 'na',
                hole_summary_list: [],
                updateInterval: null,
                updateIntervalTime: 10 * 1000,
                fans_watching_list: [],
                show_fans_watching: false,
                broadcast_contact_token: ''
            };
        },
        mounted() {
            let game_token = this.$route.params.token;
            this.broadcast_contact_token = this.$route.params.fantoken;
            this.GameRes.get({token: game_token}).then((resp) => {
                this.game = resp.body;
                this.current_hole = this.game.player_last_hole_recorded + 1;
                this.create_game_summary();
                if (!this.game.is_game_complete) {
                    if (!this.game.game_expired) {
                        this.updateInterval = setInterval(this.update, this.updateIntervalTime);
                    }
                }
                let data = {game_token: game_token, game_broadcast_contact_token: this.broadcast_contact_token};
                this.GameBroadcastContactRes.save(data).then((resp) => {
                    console.log(resp.body);
                }, () => {
                    console.error(arguments);
                });
            }, () => { console.error(arguments); });

        },
        beforeDestroy() {
            if (this.updateInterval) {
                clearInterval(this.updateInterval);
                this.updateInterval = null;
            }
        },
        methods: {
            update() {
                console.log('refresh page data');
                if (this.game.game_expired || this.game.is_game_complete) {
                    if (this.updateInterval) {
                        clearInterval(this.updateInterval);
                        this.updateInterval = null;
                    }
                } else {
                    let game_token = this.$route.params.token;
                    this.GameRes.get({token: game_token}).then((resp) => {
                        this.game = resp.body;
                        this.current_hole = this.game.player_last_hole_recorded + 1;
                        this.create_game_summary();
                    }, () => {
                        console.error(arguments);
                    });
                }
            },
            visibilityChanged(event, hidden) {
                clearInterval(this.updateInterval);
                this.updateInterval = null;
                if (!hidden) {
                    this.updateInterval = setInterval(this.update, this.updateIntervalTime);
                }
            },
            go_stats() {
                this.$router.push(`/stats/${this.game.player.token}/${this.game.token}/${this.broadcast_contact_token}/`);
            },
            convert_to_pos_number(negative_num) {
                return Math.abs(negative_num)
            },
            save_putt(num) {
                if (num === 1) {
                    this.saving_btn_1 = true;
                } else if (num === 2) {
                    this.saving_btn_2 = true;
                }  else if (num === 3) {
                    this.saving_btn_3 = true;
                }
                console.log(num);
                let data = {
                    num_putts: num,
                    game_token: this.game.token,
                    current_hole: this.current_hole,
                };
                this.GameRes.save(data).then((resp) => {
                    this.game = resp.body;
                    this.current_hole = this.game.player_last_hole_recorded + 1;
                    this.loading_putt = true;
                    this.loading_opp_putt_num = true;
                    this.create_game_summary();
                    setTimeout(() => {
                        this.loading_putt = false;
                        setTimeout(() => {
                            this.loading_opp_putt_num = false;
                            setTimeout(() => {
                                this.saving_btn_1 = false;
                                this.saving_btn_2 = false;
                                this.saving_btn_3 = false;
                            }, 500);
                        }, 500);
                    }, 500);

                }, () => {
                    console.error(arguments);
                });
            },
            formatDate(d) {
                var options = { year: 'numeric', month: 'short', day: 'numeric' };
                var d2  = new Date(d);
                var formatted_date = d2.toLocaleDateString("en-US", options);
                var formatted_time = d2.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                return formatted_date + ' ' + formatted_time
            },
            slope_title (num) {
                if (num === 1) {
                    return 'Uphill'
                } else {
                    return 'Downhill'
                }
            },
            get_putt_description (num) {
                if (num === 1) {
                    return this.game.putt_length + " Foot " + this.slope_title (this.game.putt_slope_1) + " Putt "
                } else {
                    return "testing"
                }
            },
            go_show_fans_watching() {
                this.show_fans_watching = true;
                this.GameBroadcastContactRes.get({game_token: this.game.token}).then((resp) => {
                    this.fans_watching_list = resp.body;
                }, () => { console.error(arguments); });
            },
            close_show_fans_watching() {
                this.show_fans_watching = false;
            },
            create_game_summary () {
                this.hole_summary_list = []
                for (let hole = 1; hole <= this.game.player_last_hole_recorded; hole++) {
                    if (hole === 1) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_1 + " Foot " + this.slope_title (this.game.putt_slope_1) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_1 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_1 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_1,
                            your_strokes : this.game.player_1,
                            hole_score : this.game.pro_1 - this.game.player_1
                        };
                    } else if (hole === 2) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_2 + " Foot " + this.slope_title (this.game.putt_slope_2) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_2 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_2 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_2,
                            your_strokes : this.game.player_2,
                            hole_score : this.game.pro_2 - this.game.player_2
                        };
                    } else if (hole === 3) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_3 + " Foot " + this.slope_title (this.game.putt_slope_3) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_3 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_3 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_3,
                            your_strokes : this.game.player_3,
                            hole_score : this.game.pro_3 - this.game.player_3
                        };
                    }  else if (hole === 4) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_4 + " Foot " + this.slope_title (this.game.putt_slope_4) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_4 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_4 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_4,
                            your_strokes : this.game.player_4,
                            hole_score : this.game.pro_4 - this.game.player_4
                        };
                    }  else if (hole === 5) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_5 + " Foot " + this.slope_title (this.game.putt_slope_5) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_5 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_5 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_5,
                            your_strokes : this.game.player_5,
                            hole_score : this.game.pro_5 - this.game.player_5
                        };
                    }  else if (hole === 6) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_6 + " Foot " + this.slope_title (this.game.putt_slope_6) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_6 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_6 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_6,
                            your_strokes : this.game.player_6,
                            hole_score : this.game.pro_6 - this.game.player_6
                        };
                    }  else if (hole === 7) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_7 + " Foot " + this.slope_title (this.game.putt_slope_7) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_7 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_7 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_7,
                            your_strokes : this.game.player_7,
                            hole_score : this.game.pro_7 - this.game.player_7
                        };
                    }  else if (hole === 8) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_8 + " Foot " + this.slope_title (this.game.putt_slope_8) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_8 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_8 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_8,
                            your_strokes : this.game.player_8,
                            hole_score : this.game.pro_8 - this.game.player_8
                        };
                    }  else if (hole === 9) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_9 + " Foot " + this.slope_title (this.game.putt_slope_9) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_9 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_9 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_9,
                            your_strokes : this.game.player_9,
                            hole_score : this.game.pro_9 - this.game.player_9
                        };
                    }  else if (hole === 10) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_10 + " Foot " + this.slope_title (this.game.putt_slope_10) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_10 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_10 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_10,
                            your_strokes : this.game.player_10,
                            hole_score : this.game.pro_10 - this.game.player_10
                        };
                    }  else if (hole === 11) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_11 + " Foot " + this.slope_title (this.game.putt_slope_11) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_11 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_11 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_11,
                            your_strokes : this.game.player_11,
                            hole_score : this.game.pro_11 - this.game.player_11
                        };
                    }  else if (hole === 12) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_12 + " Foot " + this.slope_title (this.game.putt_slope_12) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_12 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_12 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_12,
                            your_strokes : this.game.player_12,
                            hole_score : this.game.pro_12 - this.game.player_12
                        };
                    }  else if (hole === 13) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_13 + " Foot " + this.slope_title (this.game.putt_slope_13) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_13 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_13 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_13,
                            your_strokes : this.game.player_13,
                            hole_score : this.game.pro_13 - this.game.player_13
                        };
                    }  else if (hole === 14) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_14 + " Foot " + this.slope_title (this.game.putt_slope_14) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_14 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_14 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_14,
                            your_strokes : this.game.player_14,
                            hole_score : this.game.pro_14 - this.game.player_14
                        };
                    }  else if (hole === 15) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_15 + " Foot " + this.slope_title (this.game.putt_slope_15) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_15 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_15 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_15,
                            your_strokes : this.game.player_15,
                            hole_score : this.game.pro_15 - this.game.player_15
                        };
                    }  else if (hole === 16) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_16 + " Foot " + this.slope_title (this.game.putt_slope_16) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_16 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_16 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_16,
                            your_strokes : this.game.player_16,
                            hole_score : this.game.pro_16 - this.game.player_16
                        };
                    }  else if (hole === 17) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_17 + " Foot " + this.slope_title (this.game.putt_slope_17) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_17 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_17 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_17,
                            your_strokes : this.game.player_17,
                            hole_score : this.game.pro_17 - this.game.player_17
                        };
                    }  else if (hole === 18) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_18 + " Foot " + this.slope_title (this.game.putt_slope_18) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_18 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_18 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_18,
                            your_strokes : this.game.player_18,
                            hole_score : this.game.pro_18 - this.game.player_18
                        };
                    }  else {
                        var hole_data = {
                            hole_number: hole,
                            hole_description: 'na',
                            opponent_strokes : 'na',
                            your_strokes : 'na',
                            hole_score : 'na'
                        };

                    }
                    this.hole_summary_list.unshift(hole_data);
                }
            }

        },
        watch: {},
        computed: {
        },
    }
</script>