<style lang="less">
    .cursor-pointer {
        cursor: pointer;
    }
    .margin-top-10 {
        margin-top: 10rem !important;
    }
    .margin-top-7 {
        margin-top: 7rem !important;
    }
    .margin-top-6 {
        margin-top: 5rem !important;
    }
    .dash-btn {
        width: 100%;
        height: 8rem;
        font-size: 4rem;
    }
    .background-blue {
        background-color: #87cefa;
        color: white;
    }
    .background-green {
        background-color: forestgreen;
        color: white;
    }
    .background-red {
        background-color: red;
        color: white;
    }
    .red-text {
        color: red;
    }
    .green-text {
        color: forestgreen;
    }
    .blue-text {
        color: blue;
    }
    .dark-grey-text {
        color: #333333;
    }
    .light-grey-text {
        color: #999999;
    }
    .title-text {
        color: #666666;
        font-size: 4rem;
    }
    .input-bx {
        height: 5rem;
        font-size: 2rem;
    }
    .card-header {
        font-size: 4rem;
    }
    .score-text {
        font-size: 14rem;
    }
    .score-subtitle-text {
        font-size: 3rem;
    }
</style>

<template>
    <div>
        <div v-if="loading_new_game" class="text-center margin-top-10">
            <i class="fad fa-circle-notch fa-spin fast-spin green-text fa-10x"></i>
            <div class="title-label green-text text-center mt-5">
                Loading Game..
            </div>
        </div>

        <div v-if="!loading_new_game">
            <div v-if="!player.first_name || !player.last_name" class="text-center">
                <div class="title-text margin-top-7">
                     Welcome!
                </div>
                <div class="pl-5 pr-5 mt-5">
                    <b-input  v-model="player_first_name_field" type="text" class="input-bx" placeholder="Your first name" autofocus></b-input>
                    <b-input  v-model="player_last_name_field" type="text" class="input-bx mt-4" placeholder="Last name"></b-input>
                    <b-button class="dash-btn mt-5" variant="success" size="lg" @click="save_name()" :disabled="disable_next_btn">Next <i class="far fa-chevron-right small"></i></b-button>
                </div>

            </div>
            <div v-if="player.first_name && player.last_name">
                <b-row>
                    <b-col class="cursor-pointer"><i class="fad fa-home-alt fa-4x mt-3 ml-5 light-grey-text"></i></b-col>
                    <b-col class="cursor-pointer text-center" @click="go_fans()"><i class="fad fa-user-friends fa-4x mt-3 light-grey-text"></i></b-col>
                    <b-col class="cursor-pointer text-center" @click="go_stats()"><i class="fad fa-trophy fa-4x mt-3 light-grey-text"></i></b-col>
<!--                    <b-col></b-col>-->
<!--                    <b-col></b-col>-->
<!--                    <b-col></b-col>-->
                    <b-col class="cursor-pointer"><i class="fal fa-user-cog fa-4x mt-3 mr-5 float-right light-grey-text" @click="go_account_settings()"></i></b-col>
                </b-row>
                <hr>
                <div class="text-center mr-5 ml-5 margin-top-7">
                    <b-button class="dash-btn" variant="success" size="lg" @click="new_game()">New Game</b-button>
                </div>
                <div class="text-center mr-5 ml-5 margin-top-7">
                    <b-card bg-variant="default" header="Putting Score" class="text-center">
                        <div class="score-text green-text">
                            <span v-if="!loading_score">{{player.putting_score}}</span>
                            <b-spinner v-if="loading_score" class="green-color mb-5" style="width: 12rem; height: 12rem;" type="grow"></b-spinner>
                        </div>
                        <template #footer>
                            <div class="score-subtitle-text">
                                <span v-if="loading_score">loading score..</span>
                                <span v-if="!loading_score">{{player.putting_score_title}}</span>
                            </div>
                        </template>
                    </b-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Dashboard",
        data() {
            return {
                PlayerRes: this.$resource('/api/player{/token}/'),
                player: {},
                player_first_name_field: "",
                player_last_name_field: "",
                GameRes: this.$resource('/api/game{/token}/'),
                loading_new_game: true,
                disable_next_btn: true,
                loading_score: true,
            };
        },
        mounted() {
            let player_token = this.$route.params.token;
            this.PlayerRes.get({token: player_token}).then((resp) => {
                this.player = resp.body;
                this.player_first_name_field = this.player.first_name;
                this.player_last_name_field = this.player.last_name;
                setTimeout(() => {
                    this.loading_new_game = false;
                    setTimeout(() => {
                        this.loading_score = false;
                    }, 2000);
                }, 1000);
            }, () => { console.error(arguments); });
        },
        methods: {
            new_game() {
                console.log('Create New Game!');
                this.loading_new_game = true;
                let data = {
                    save_type: 'create_new_game',
                    player_token: this.player.token,
                };
                this.GameRes.save(data).then((resp) => {
                    setTimeout(() => {
                        this.$router.push(`/game/${resp.body.token}/`);
                    }, 1000);
                }, () => {
                    console.error(arguments);
                });
            },
            go_stats() {
                this.$router.push(`/stats/${this.player.token}/na/na/`);
            },
            go_fans() {
                this.$router.push(`/fans/${this.player.token}/`);
            },
            go_account_settings() {
                this.$router.push(`/settings/${this.player.token}/`);
            },
            save_name() {
                this.loading_new_game = true;
                console.log('save name');
                console.log(this.player_first_name_field);
                let data = {
                    save_type: 'save_name',
                    player_token: this.player.token,
                    player_first_name: this.player_first_name_field,
                    player_last_name: this.player_last_name_field
                };
                this.PlayerRes.save(data).then((resp) => {
                    console.log('Name saved!');
                    console.log(resp.body);
                    this.player = resp.body;
                    setTimeout(() => {
                        this.loading_new_game = false;
                    }, 1000);

                }, () => {
                    console.error(arguments);
                });
            }
        },
        watch: {
            player_first_name_field(n, o) {
                if (n !== "") {
                    this.disable_next_btn = false;
                } else {
                    this.disable_next_btn = true;
                }
            }
        },
        computed: {
        },

    }
</script>
