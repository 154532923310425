//Libraries
import _ from 'underscore';
import cookies from 'browser-cookies';
import Vue from 'vue';
import VueRouter from 'vue-router';
import VueResource from 'vue-resource';
import BootstrapVue from 'bootstrap-vue';
import visibility from 'vue-visibility-change';

// css imports
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css';
import './fontawesome/css/all.css'
import './fontawesome/css/brands.css'

// Components
import App from './App'
import Home from './Home'
import Resume from './Resume'
import Game from "./Game";
import GameBroadcast from "./GameBroadcast";
import Dashboard from "./Dashboard";
import Stats from "./Stats"
import Fans from "./Fans";
import AccountSettings from "./AccountSettings"

Vue.use(VueRouter);
Vue.use(VueResource);
Vue.use(BootstrapVue);
Vue.use(visibility);

let router = new VueRouter({
    mode: 'history',
    history: true,
    routes: [
        {path: '/dashboard/:token/', name: 'Dashboard', component: Dashboard, meta: {title: 'Dashboard'}},
        {path: '/settings/:token/', name: 'AccountSettings', component: AccountSettings, meta: {title: 'Account Settings'}},
        {path: '/game/:token/', name: 'Game', component: Game, meta: {title: 'Game'}},
        {path: '/stats/:token/:game/:fantoken/', name: 'Stats', component: Stats, meta: {title: 'Stats'}},
        {path: '/broadcast/:token/:fantoken/', name: 'GameBroadcast', component: GameBroadcast, meta: {title: 'GameBroadcast'}},
        {path: '/fans/:token/', name: 'Fans', component: Fans, meta: {title: 'Fans'}},
        {path: '/:token/:playername/', name: 'Resume', component: Resume, meta: {title: 'Resume'}},
        {path: '/:playername/', name: 'Resume', component: Resume, meta: {title: 'Resume'}},
        {path: '/', name: 'Home', component: Home, meta: {title: 'Home'}},

    ],
});

Vue.http.headers.common['X-CSRFToken'] = cookies.get('csrftoken');

let app = window.app = new Vue({
    name: 'Root',
    el: '#app',
    router: router,
    data() {
        let d = _.clone(window.CRVars || {});
        d['csrf'] = cookies.get('csrftoken');
        // d.csrf = cookies.get('csrftoken');
        return d;
    },
    components: { App },
    mounted() {
      this.$on('login', () => {
          // Listen for login event
          this.$children[0].showLogin = true;
      });
      if (this.logged_in && this.$route.meta.goHome) {
          this.$router.push('/');
      }
    },
    methods: {},
    computed: {},
    render(r) {
        return r(App);
    },
});