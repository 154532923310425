<style lang="less">
    .grey-color {
        color: #CCCCCC;
    }
    .dark-grey-color {
        color: #555555;
    }
    .blue-color {
        color: #007bff;
    }
    .red-color {
        color: #ff0000;
    }
    .blue-color {
        color: #007bff;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .timer-font {
        font-family: San Francisco, Helvetica Neue, Menlo-Bold, Menlo-Regular, Menlo, monospace;
    }
    .custom-select {
        height: 6rem;
        font-size: 3rem;
        padding-left: 2rem;
    }
    .loading-workout-text {
        font-size: 4rem;
    }
    .loading-workout-animation-text {
        height: 320px;
        padding-top: 70px;
        font-size: 6rem;
    }
    .current-level-text {
        font-size: 6rem;
    }
    .next-level-text {
        font-size: 3rem;
    }
    .progress {
        height: 6rem;
        font-size: 2rem;
    }
    .goal-text {
        font-size: 3rem;
    }
    .modal-main-text {
        font-size: 4rem;
    }
      .modal-xl {
        width: 90%;
       max-width:1200px;
      }

    .exit-btn {
        font-size: 3rem;
    }
    .ok-btn {
        font-size: 5rem;
    }

</style>
<template>
    <div>
        <div v-if="!$root.logged_in">
            Show Login
        </div>
        <div>
            Show Search Box
        </div>
        <div class="ml-5 mt-5">
            <b-button @click="testApi()">Test API</b-button>
        </div>



    </div>
</template>

<script>
    import _ from "underscore";
    export default {
        name: "Home",
        data() {
            return {
                GameRes: this.$resource('/api/game{/token}/'),
                PlayerRes: this.$resource('/api/player{/token}/'),
                WorkoutRes: this.$resource('/api/workouts{/token}/'),
                WorkoutSessionRes: this.$resource('/api/workoutsession{/token}/'),
                HiitLevelsRes: this.$resource('/api/hiitlevels{/token}/'),
                DayRes: this.$resource('/api/day{/token}/'),
                showSelectWorkout: true,
                showLoadingWorkout: false,
                showWorkoutLoadingText: false,
                workoutLoadingText: "GAME ON!",
                showWorkout: false,
                hiit_levels: [],
                activeLevelNumber: 1,
                current_hiit_level: {},
                next_hiit_level: {},
                updateInterval: null,
                updateIntervalTime: 1000,
                timePerLevel: 60,
                timerNum: 60,
                totalTime: '0:00',
                showStartBtn: true,
                selectedWorkout: null,
                workoutOptions: [
                     {value: null, text: 'Select Workout'},
                ],
                disableGoalDropdown: true,
                selectedGoal: null,
                goalOptions: [
                    {value: null, text: 'Select Goal'},
                    {value: 1, text: '1 minute'},
                    {value: 2, text: '2 minutes'},
                    {value: 3, text: '3 minutes'},
                    {value: 4, text: '4 minutes'},
                    {value: 5, text: '5 minutes'},
                    {value: 7, text: '7 minutes'},
                    {value: 8, text: '8 minutes'},
                    {value: 9, text: '9 minutes'},
                    {value: 10, text: '10 min - Beginner'},
                    {value: 11, text: '11 minutes'},
                    {value: 12, text: '12 minutes'},
                    {value: 13, text: '13 minutes'},
                    {value: 14, text: '14 minutes'},
                    {value: 15, text: '15 minutes'},
                    {value: 16, text: '16 minutes'},
                    {value: 17, text: '17 minutes'},
                    {value: 18, text: '18 minutes'},
                    {value: 19, text: '19 minutes'},
                    {value: 20, text: '20 min - Intermediate'},
                    {value: 21, text: '21 minutes'},
                    {value: 22, text: '22 minutes'},
                    {value: 23, text: '23 minutes'},
                    {value: 24, text: '24 minutes'},
                    {value: 25, text: '25 minutes'},
                    {value: 26, text: '26 minutes'},
                    {value: 27, text: '27 minutes'},
                    {value: 28, text: '28 minutes'},
                    {value: 29, text: '29 minutes'},
                    {value: 30, text: '30 min - Advanced'},
                ],
                progressValue: 50,
                progressMax: 100,
                workoutSession: {},
            };
        },
        mounted() {
            console.log(this.$root.logged_in)
            if (this.$root.logged_in) {
                // load the workout drop here - put login here if don't want to show all the workouts
                this.WorkoutRes.get().then((resp) => {
                    _.each(resp.body, (workout) => this.populateWorkoutDropdown(workout));
                }, () => {
                    this.error = true;
                });
                // there is a workout token in url if a workout has been selected
                // would only have workout token if page refreshed or went directly
                let workoutSessionToken = this.$route.params.token;
                if (workoutSessionToken) {
                    console.log('LOAD then Show Workout where it last stopped');
                    this.showSelectWorkout = false;
                    this.showLoadingWorkout = true;
                    this.WorkoutSessionRes.get({token: workoutSessionToken}).then((resp) => {
                        this.workoutSession = resp.body;
                        this.selectedGoal = this.workoutSession.workout_goal;
                        // activeLevelNumber is last_level_complete + 1 because now "into" next level
                        this.activeLevelNumber = this.workoutSession.last_level_complete + 1;
                        this.progressMax = this.workoutSession.workout_goal * 60;
                        // progressValue is always at least 10% of progressMax so time show in bar
                        if (this.workoutSession.last_level_complete * 60 > this.progressMax / 10) {
                            this.progressValue = this.workoutSession.last_level_complete * 60;
                        } else {
                            this.progressValue = this.progressMax / 10
                        }
                        this.HiitLevelsRes.get({selected_workout_token: this.workoutSession.workout.token}).then((resp) => {
                            this.hiit_levels = resp.body;
                            setTimeout(() => {
                                this.totalWorkoutTime();
                                if (this.workoutSession.last_level_complete > 0) {
                                    var load_level = this.workoutSession.last_level_complete + 1;
                                } else {
                                    var load_level = 1;
                                }
                                this.loadWorkoutLevel(load_level);
                            }, 1500);
                        }, () => {
                            this.error = true;
                        });
                    }, () => { console.error(arguments); });

                } else {
                    // check if user has an "in_progress" workout
                    // if true show that workout
                    console.log('Check if user has "in_progress" workout for TODAY');


                    // else who the "select workout" and calendar

                }
            }
        },
        methods: {
            testApi() {
                console.log('Ben Test update to API');
                this.GameRes.update({token: 'qhcc7xCPRx'}, {'test': 'test1'}).then((resp) => {
                    console.log(resp.body);
                    }, () => {
                        console.error(arguments);
                    });
            },
            populateWorkoutDropdown(workout) {
                this.workoutOptions.push({'value': workout, 'text': workout.name});
            },
            showExitWorkoutConfirm() {
                this.pauseTimer();
                this.$refs.exitWorkoutConfirmRef.show();
            },
            exitWorkout() {
                this.$router.push('/');
                this.$root.headerText = 'GamePlan30'
                // if within 1 minute of goal mark day as "complete"
                if (this.activeLevelNumber >= this.selectedGoal) {
                    console.log('activeLevelNumber: '+this.activeLevelNumber);
                    console.log('selectedGoal: '+this.selectedGoal);
                    console.log('Mark day as complete')
                    this.DayRes.update({token: this.workoutSession.day.token}, {status: 1})

                } else {
                    console.log('Mark day as partial complete')
                    console.log(this.workoutSession.day.token);
                    this.DayRes.update({token: this.workoutSession.day.token}, {status: 3})
                }
                this.showSelectWorkout = true;
                this.showLoadingWorkout = false;
                this.showWorkout = false;
                this.selectedWorkout = null;
                this.selectedGoal = null;
                this.workoutSession = null;
                this.$refs.exitWorkoutConfirmRef.hide();
            },
            cancelExitWorkout() {
                this.$refs.exitWorkoutConfirmRef.hide();
            },
            workoutComplete() {
                // set workout status to complete
                this.$router.push('/');
                this.$root.headerText = 'GamePlan30'
                this.showSelectWorkout = true;
                this.showLoadingWorkout = false;
                this.showWorkout = false;
                this.selectedWorkout = null;
                this.selectedGoal = null;
                this.$refs.reachedGoalModalRef.hide();
            },
            loadWorkoutLevel(level_number) {
                // current_hiit_level is zero based index current is level_number - 1
                this.current_hiit_level = this.hiit_levels[level_number-1];
                this.next_hiit_level = this.hiit_levels[level_number];
                this.showSelectWorkout = false;
                this.showLoadingWorkout = false;
                this.showWorkout = true;
                if (this.workoutSession.status !== 1) {
                    this.showReachedGoalModal();
                }

            },
            startTimer() {
                this.showWorkoutLoadingText = true;
                this.showStartBtn = false;
                this.workoutLoadingText = "Ready..";
                setTimeout(() => {
                this.workoutLoadingText = "Set..";
                    setTimeout(() => {
                        this.workoutLoadingText = "GAME ON!";
                        setTimeout(() => {
                            this.updateInterval = setInterval(this.countdownTimer, this.updateIntervalTime);
                            this.showWorkoutLoadingText = false;
                        }, 1500);
                    }, 1500);
                }, 1500);

            },
            pauseTimer() {
                this.showStartBtn = true;
                if (this.updateInterval) {
                    clearInterval(this.updateInterval);
                    this.updateInterval = null;
                }
            },
            countdownTimer() {
                if (this.timerNum <= 1) {
                    if (this.activeLevelNumber >= this.selectedGoal || this.activeLevelNumber >= 30) {
                        console.log('Show Reached Goal Modal!');
                        this.saveWorkout(this.activeLevelNumber);
                        this.showReachedGoalModal();
                    } else {
                        this.activeLevelNumber += 1;
                        this.loadWorkoutLevel(this.activeLevelNumber);
                        this.timerNum = this.timePerLevel;
                        this.saveWorkout(this.activeLevelNumber - 1);
                    }
                } else {
                    this.timerNum -= 1;
                }
                this.totalWorkoutTime();
            },
            resetTimer() {
                // consider pause
                this.timerNum = this.timePerLevel
            },
            totalWorkoutTime() {
                let minutes = this.activeLevelNumber - 1;
                let seconds = this.timePerLevel - this.timerNum;
                if (seconds < 10) {
                    this.totalTime = minutes+':0'+seconds;
                } else {
                    this.totalTime = minutes+':'+seconds;
                }
                // update progress bar - using seconds
                let total_seconds = (minutes * 60) + seconds;
                if (total_seconds > this.progressValue) {
                    this.progressValue = total_seconds;
                }
            },
            showReachedGoalModal() {
                if (this.updateInterval) {
                    clearInterval(this.updateInterval);
                    this.updateInterval = null;
                }
                this.DayRes.update({token: this.workoutSession.day.token}, {status: 1})
                this.$refs.reachedGoalModalRef.show();
            },
            saveWorkout(last_level_complete) {
                // save workout progress every minute
                console.log('Save workout!')
                let data = {
                    last_level_complete: last_level_complete,
                };
                this.WorkoutSessionRes.update({token: this.workoutSession.token}, data).then((resp) => {
                    console.log(resp.body);
                }, () => {
                    console.error(arguments);
                });
            },
        },
        watch: {
            selectedWorkout(newWorkout, oldWorkout) {
                this.disableGoalDropdown = !newWorkout;
            },
            selectedGoal(newGoal, oldGoal) {
                if (!newGoal) {return}
                // if has this.$route.params.token means it is a refresh so return out
                if (this.$route.params.token) {return}
                this.showSelectWorkout = false;
                this.showLoadingWorkout = true;
                this.showWorkout = false;
                this.$root.headerText = this.selectedWorkout.name;
                this.showStartBtn = true;
                this.activeLevelNumber = 1;
                this.timerNum = this.timePerLevel;
                // make progressValue 10% of max so room to display the "time"
                // covert from minutes to seconds so progress is more granular
                this.progressMax = newGoal * 60;
                this.progressValue = this.progressMax / 10

                // Create WorkoutSession then put token in URL and show workout
                if (this.$refs.calendarRef) {
                    var current_day = this.$refs.calendarRef.currentDay
                } else {
                    var current_day = 'na'
                }
                let data = {
                    workout_token: this.selectedWorkout.token,
                    day_token: current_day.token,
                    workout_goal: newGoal,
                };
                this.WorkoutSessionRes.save(data).then((resp1) => {
                    console.log(resp1.body);
                    this.workoutSession = resp1.body;
                    let workout_session_token = resp1.body.token;
                    this.$router.push(`/${workout_session_token}/`);
                    this.HiitLevelsRes.get({selected_workout_token: this.selectedWorkout.token}).then((resp) => {
                        this.totalWorkoutTime();
                        this.hiit_levels = resp.body;
                        setTimeout(() => {
                            this.loadWorkoutLevel(1);
                        }, 1500);
                    }, () => {
                        this.error = true;
                    });
                });
            },
        },
        computed: {
            userProfile: {
                get() {
                    return this.$root.userProfile;
                },
                set(value) {
                    this.$root.userProfile = value;
                },
            },
        },
    }
</script>