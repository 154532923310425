<style scoped lang="less">
.nav-bar{
    height: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;

    background-color: #CCCCCC;
    border-bottom-color: #dddddd;
    border-bottom-style: solid;
    color: #ffffff;
            /*font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;*/
    font-size: 5rem;
    font-weight: 600;
    letter-spacing: 0.05em;
}
</style>

<template>
    <div>
        <!--nav menu etc-->
<!--        <div class="nav-bar text-center">-->
<!--            <span>{{$root.headerText}}</span>-->
<!--        </div>-->
        <router-view></router-view>
        <!--footer-->
    </div>
</template>

<script>
    export default {
        name: "App",
        data() {
            return {
            };
        },
        mounted() {
        },
        methods: {},
        computed: {},
    }
</script>