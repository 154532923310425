<style lang="less">
    .cursor-pointer {
        cursor: pointer;
    }
    .margin-top-10 {
        margin-top: 10rem !important;
    }
    .margin-top-7 {
        margin-top: 7rem !important;
    }
    .margin-top-6 {
        margin-top: 5rem !important;
    }
    .margin-left-7 {
        margin-left: 7rem !important;
    }
    .margin-right-7 {
        margin-right: 7rem !important;
    }
    .background-blue {
        background-color: #87cefa;
        color: white;
    }
    .background-green {
        background-color: forestgreen;
        color: white;
    }
    .background-red {
        background-color: red;
        color: white;
    }
    .red-text {
        color: red;
    }
    .green-text {
        color: forestgreen;
    }
    .blue-text {
        color: blue;
    }
    .dark-grey-text {
        color: #333333;
    }
    .light-grey-text {
        color: #999999;
    }
    .label-text {
        color: #999999;
        font-size: 2rem;
    }
    .profile-text {
        color: #666666;
        font-size: 3rem;
        line-height: 1;
    }
    .dash-btn {
        width: 100%;
        height: 8rem;
        font-size: 4rem;
    }
        .input-bx {
        height: 5rem;
        font-size: 2rem;
    }

</style>

<template>
    <div>
        <b-row class="ml-3 mt-3">
            <b-col cols="1">
                <i class="fal fa-chevron-left fa-4x light-grey-text mt-3" @click="go_back_btn()"></i>
            </b-col>
            <b-col cols="10" class="title-label text-center">
                <div class="mt-2">Account Settings</div>
            </b-col>
            <b-col cols="1"></b-col>
        </b-row>
        <hr>

        <div v-if="loading_new_game" class="text-center margin-top-10">
            <i class="fad fa-circle-notch fa-spin fast-spin green-text fa-10x"></i>
            <div class="title-label green-text text-center mt-5">
                Loading Account..
            </div>
        </div>
        <div v-if="!loading_new_game">

            <div class="ml-5 mr-5 margin-top-7">
                <b-row class="label-text">
                    <b-col>
                        Name
                    </b-col>
                </b-row>
                <b-row v-if="!show_edit_name">
                    <b-col cols="10" class="profile-text">
                        {{player.fullname}}
                    </b-col>
                    <b-col cols="2" @click="edit_name()">
                        <i class="fas fa-pencil mt-2 float-right label-text"></i>
                    </b-col>
                </b-row>
                <b-row v-if="show_edit_name">
                    <b-col cols="10" class="profile-text">
                        <b-input v-model="editNameText" type="text" class="input-bx" placeholder="Your full name" autofocus></b-input>
                    </b-col>
                    <b-col cols="2" @click="cancel_edit_name()">
                        <i class="fas fa-times fa-4x mt-2 float-right light-grey-text"></i>
                    </b-col>
                </b-row>
            </div>
            <div class="ml-5 mr-5 mt-5">
                <b-row class="label-text">
                    <b-col>
                        Mobile
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="11" class="profile-text">
                        {{player.mobile_display}}
                    </b-col>
                    <b-col cols="1">
                        <i class="fas fa-pencil mt-2 float-right label-text "></i>
                    </b-col>
                </b-row>
            </div>
            <div class="ml-5 mr-5 mt-5">
                <b-row class="label-text">
                    <b-col>
                        State
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="11" class="profile-text">
                        Arizona
                    </b-col>
                    <b-col cols="1">
                        <i class="fas fa-pencil mt-2 float-right label-text "></i>
                    </b-col>
                </b-row>
            </div>

            <div class="text-center mr-5 ml-5 margin-top-7">
                <b-button class="dash-btn" variant="success" size="lg" :disabled="disableSaveBtn">Save</b-button>
            </div>

        </div>

    </div>
</template>

<script>
    export default {
        name: "AccountSettings",
        data() {
            return {
                PlayerRes: this.$resource('/api/player{/token}/'),
                player: {},
                disableSaveBtn:true,
                loading_new_game: true,
                show_edit_name: false,
                editNameText: ""
            };
        },
        mounted() {
            let player_token = this.$route.params.token;
            console.log(player_token);
            this.PlayerRes.get({token: player_token}).then((resp) => {
                this.player = resp.body;
                this.editNameText = this.player.fullname;
                console.log(this.player);
                setTimeout(() => {
                    this.loading_new_game = false;
                }, 1000);
            }, () => { console.error(arguments); });
        },
        methods: {
            go_back_btn() {
                this.$router.push(`/dashboard/${this.player.token}/`);
            },
            edit_name() {
                console.log('Edit name');
                this.editNameText = this.player.fullname;
                this.show_edit_name = true;
            },
            cancel_edit_name() {
                this.show_edit_name = false;
            }
        },
        watch: {
        },
        computed: {
        },

    }
</script>