
<style lang="less">
    .cursor-pointer {
        cursor: pointer;
    }
    .main-body {
        padding-top: 45px;
        margin-left: 20%;
        margin-right: 20%;
    }

</style>

<template>
    <div>

          <b-navbar toggleable="lg" type="dark" variant="info">
    <b-navbar-brand href="#">My Golf Resume</b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
<!--      <b-navbar-nav>-->
<!--        <b-nav-item href="#">Link</b-nav-item>-->
<!--        <b-nav-item href="#" disabled>Disabled</b-nav-item>-->
<!--      </b-navbar-nav>-->

      <!-- Right aligned nav items -->
<!--      <b-navbar-nav class="ml-auto">-->
<!--        <b-nav-form>-->
<!--          <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>-->
<!--          <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>-->
<!--        </b-nav-form>-->

<!--        <b-nav-item-dropdown text="Lang" right>-->
<!--          <b-dropdown-item href="#">EN</b-dropdown-item>-->
<!--          <b-dropdown-item href="#">ES</b-dropdown-item>-->
<!--          <b-dropdown-item href="#">RU</b-dropdown-item>-->
<!--          <b-dropdown-item href="#">FA</b-dropdown-item>-->
<!--        </b-nav-item-dropdown>-->

<!--        <b-nav-item-dropdown right>-->
<!--          &lt;!&ndash; Using 'button-content' slot &ndash;&gt;-->
<!--          <template #button-content>-->
<!--            <em>User</em>-->
<!--          </template>-->
<!--          <b-dropdown-item href="#">Profile</b-dropdown-item>-->
<!--          <b-dropdown-item href="#">Sign Out</b-dropdown-item>-->
<!--        </b-nav-item-dropdown>-->
<!--      </b-navbar-nav>-->
    </b-collapse>
  </b-navbar>


        <div class="main-body">

                <b-card img-src="https://golfresume.s3.amazonaws.com/preston-golf-resume.jpg" img-alt="preston-brooke-image" img-right img-width="300" img-height="300">
                  <b-card-text class="ml-3">

                          <h1>{{resume.player_first_name}} {{resume.player_last_name}}</h1>
                          <h6>Class of {{resume.graduation_year}} - {{resume.city}}, {{resume.state}}</h6>
                          <h6 class="mt-4"><i class="fal fa-mobile-alt"></i> {{resume.mobile_display}}</h6>
                          <h6 class="mt-3"><i class="fal fa-envelope"></i> prestonbrookeaz@gmail.com</h6>
                          <h6 class="mt-3"><i class="fab fa-instagram"></i> @prestonbrooke27</h6>
                          <b-button class="mt-4" variant="outline-primary">Follow Player</b-button>

                  </b-card-text>
                </b-card>

            <b-card class="mt-5">
                <b-card-header  class="cursor-pointer" header-tag="header" @click="toggle_expander('visible_jgs_ranking')">
                        <i v-if="visible_jgs_ranking" class="fas fa-minus mr-3"></i>
                        <i v-else class="fas fa-plus mr-3"></i>
                        Junior Golf Scoreboard Ranking
                </b-card-header>
                <b-collapse id="collapse-1" v-model="visible_jgs_ranking" >
                    <b-card-text  class="mt-3 ml-3">
                        <b-table ref="jgsRankingTable" class='cursor-pointer' :items="jgs_ranking_list" :fields="jgsRankingFields" striped bordered borderless hover :busy="loadingJgsRank">
                            <template #table-caption><small>last updated {{ formatDate(resume.jgs_ranking_last_updated) }}</small></template>

                        </b-table>
                    </b-card-text>
                </b-collapse>
            </b-card>


            <b-card class="mt-5">
                <b-card-header  class="cursor-pointer" header-tag="header" @click="toggle_expander('visible_personal_info')">

                        <i v-if="visible_personal_info" class="fas fa-minus mr-3"></i>
                        <i v-else class="fas fa-plus mr-3"></i>

                        Personal Information

                </b-card-header>
                <b-collapse id="collapse-1" v-model="visible_personal_info" >
                    <b-card-text  class="mt-3 ml-3">
                        address, height weight etc
                    </b-card-text>
                </b-collapse>
            </b-card>

            <b-card class="mt-5">
                <b-card-header header-tag="header" v-b-toggle.collapse-2>
                    <span><i class="fas fa-plus mr-3"></i> Academics</span>
                </b-card-header>
                <b-collapse id="collapse-2" visible >
                    <b-card-text  class="mt-3 ml-3">
                        gpa
                    </b-card-text>
                </b-collapse>
            </b-card>

            <b-card class="mt-5">
                <b-card-header header-tag="header" v-b-toggle.collapse-3>
                    <span><i class="fas fa-plus mr-3"></i> Tournament Results</span>
                </b-card-header>
                <b-collapse id="collapse-3" visible >
                    <b-card-text  class="mt-3 ml-3">
                        tournament table
                    </b-card-text>
                </b-collapse>
            </b-card>

            <b-card class="mt-5">
                <b-card-header header-tag="header" v-b-toggle.collapse-4>
                    <span><i class="fas fa-plus mr-3"></i> Upcoming Tournaments</span>
                </b-card-header>
                <b-collapse id="collapse-4" visible >
                    <b-card-text  class="mt-3 ml-3">
                        tournament table
                    </b-card-text>
                </b-collapse>
            </b-card>

            <b-card class="mt-5">
                <b-card-header header-tag="header" v-b-toggle.collapse-5>
                    <span><i class="fas fa-plus mr-3"></i> Coaches</span>
                </b-card-header>
                <b-collapse id="collapse-5" visible >
                    <b-card-text  class="mt-3 ml-3">
                        coach list
                    </b-card-text>
                </b-collapse>
            </b-card>

            <b-card class="mt-5">
                <b-card-header header-tag="header" v-b-toggle.collapse-6>
                    <span><i class="fas fa-plus mr-3"></i> References</span>
                </b-card-header>
                <b-collapse id="collapse-6" visible >
                    <b-card-text  class="mt-3 ml-3">
                        reference list
                    </b-card-text>
                </b-collapse>
            </b-card>



        </div>
    </div>
</template>

<script>
    export default {
        name: "Resume",
        data() {
            return {
                ResumeRes: this.$resource('/api/resume{/token}/'),
                TournamentRes: this.$resource('/api/tournament{/token}/'),
                JgsRankingRes: this.$resource('/api/jgsranking{/token}/'),
                resume: {},
                tournament_list: [],
                jgs_ranking_list: [],
                visible_jgs_ranking: true,
                visible_personal_info: true,
                loadingJgsRank: true,
                jgsRankingFields: [
                    {
                        key: 'year',
                        label: 'Year',
                        class: 'text-center',
                    },
                    {
                        key: 'state_class_rank',
                        label: "State Class Rank",
                        class: 'text-center',
                    },
                    {
                        key: 'state_total_rank',
                        label: "State Total Rank",
                        class: 'text-center',
                    },
                    {
                        key: 'national_class_rank',
                        label: "National Class Rank",
                        class: 'text-center',
                    },
                    {
                        key: 'national_total_rank',
                        label: "National Total Rank",
                        class: 'text-center',
                    },
                    {
                        key: 'scoring_diff',
                        label: "Scoring Diff",
                        class: 'text-center',
                    }
                ]
            };
        },
        mounted() {
            let token = this.$route.params.token;
            let player_name = this.$route.params.playername;
            console.log(token);
            console.log(player_name);
            this.ResumeRes.get({player_name: player_name, access_token: token}).then((resp) => {
                this.resume = resp.body;
                console.log(this.resume);
                this.TournamentRes.get({player_name: player_name}).then((resp) => {
                    this.tournament_list = resp.body;
                    console.log(this.tournament_list);
                    this.JgsRankingRes.get({player_name: player_name}).then((resp) => {
                        this.jgs_ranking_list = resp.body;
                        this.loadingJgsRank = false;
                        console.log(this.jgs_ranking_list);
                    }, () => { console.error(arguments); });
                }, () => { console.error(arguments); });
            }, () => { console.error(arguments); });

        },
        methods: {
            toggle_expander(section) {
                if (section === 'visible_personal_info'){
                    this.visible_personal_info = !this.visible_personal_info;
                } else if (section === 'visible_jgs_ranking'){
                    this.visible_jgs_ranking = !this.visible_jgs_ranking;
                }
            },
            formatDate(d) {
                var options = { year: 'numeric', month: 'short', day: 'numeric' };
                var d2  = new Date(d);
                return d2.toLocaleDateString("en-US", options);
            },
        },
        watch: {},
        computed: {
            formatPhone(raw) {
                if (!raw) return '';
                let cleaned = raw.replace(/\D/g, '');
                if (cleaned.substring(0,1) === "1") {
                    cleaned = cleaned.substring(1,cleaned.length)
                }
                let substring = cleaned.substring(0, 10);
                if (cleaned.length >= 10) {
                    let match = substring.match(/^(\d{3})(\d{3})(\d{4})$/);
                    return `(${match[1]}) ${match[2]}-${match[3]}`;
                }
                if (cleaned.length >= 7) {
                    let match = substring.match(/^(\d{3})(\d{3})(\d{1,4})$/);
                    return `(${match[1]}) ${match[2]}-${match[3]}`;
                }
                if (cleaned.length == 6) {
                    let match = substring.match(/^(\d{3})(\d{0,3})$/);
                    return `(${match[1]}) ${match[2]}-`;
                }
                if (cleaned.length >= 4) {
                    let match = substring.match(/^(\d{3})(\d{0,3})$/);
                    return `(${match[1]}) ${match[2]}`;
                }
                if (cleaned.length == 3) {
                    return `(${substring}) `;
                }
                return cleaned;
            },
        },
    }
</script>