
<style lang="less">
    .cursor-pointer {
        cursor: pointer;
    }
    .main-body {
        padding-top: 45px;
        margin-left: 20%;
        margin-right: 20%;
    }
    .title-label {
        font-size: 3rem;
    }
    .sub-label-small {
        font-size: 2rem;
    }
    .large-text {
        font-size: 7rem;
    }
    .scorecard-cell {
        height: 5rem;
    }
    .scorecard-font-size {
        font-size: 3rem;
    }
    .putt-btn {
        height: 7rem;
        font-size: 4rem;
        color: #666666;
    }
    .margin-top-10 {
        margin-top: 10rem !important;
    }
    .margin-top-7 {
        margin-top: 7rem !important;
    }
    .margin-top-6 {
        margin-top: 5rem !important;
    }
    .background-blue {
        background-color: #87cefa;
        color: white;
    }
    .background-green {
        background-color: forestgreen;
        color: white;
    }
    .background-red {
        background-color: red;
        color: white;
    }
    .red-text {
        color: red;
    }
    .green-text {
        color: forestgreen;
    }
    .blue-text {
        color: blue;
    }
    .dark-grey-text {
        color: #333333;
    }
    .light-grey-text {
        color: #999999;
    }
    .fast-spin {
      -webkit-animation: fa-spin .7s infinite linear;
      animation: fa-spin .7s infinite linear;
    }
    .start-game-btn {
        width: 100%;
        height: 8rem;
        font-size: 4rem;
    }
    .contact-name {
        font-size: 4rem;
        color: #999999;
    }
    .contact-name-green {
        font-size: 4rem;
        color: forestgreen;
    }
    .contact-mobile {
        font-size: 3rem;
        color: #999999;
    }
    .contact-mobile-green {
        font-size: 3rem;
        color: forestgreen;
    }
    .add-fans-btn {
        width: 100%;
        height: 8rem;
        font-size: 4rem;
    }
    .badge-font-size {
        font-size: 2rem;
    }
    .name-initials-box {
        font-size: 8rem;
        background-color: forestgreen;
        color: white;
    }
</style>

<template>
    <div>
<!--                <div class="ml-5 mt-5">-->
<!--            <b-button @click="testApi()">Test API</b-button>-->
<!--        </div>-->

        <div v-if="show_fans_watching">
            <b-row class="ml-3  mr-3 mt-3">
                <b-col cols="1">
                    <i class="fal fa-chevron-left fa-4x light-grey-text mt-3" @click="close_show_fans_watching()"></i>
                </b-col>
                 <b-col cols="10" class="title-label text-center">
                     <div v-if="!game.is_game_complete">Fans Watching ({{fans_watching_list.length}})</div>
                     <div v-if="game.is_game_complete">Fans Who Watched ({{fans_watching_list.length}})</div>
                     <div v-if="game.type === 1" class="h3">Putting Match Play</div>
                     <div class="h3">{{formatDate(game.start)}}</div>
                 </b-col>
                <b-col cols="1">
                </b-col>
            </b-row>
            <div v-for="game_broadcast_contact in fans_watching_list" class="ml-5 mr-5 border mb-4 mt-5">
                 <b-row>
                    <b-col cols="3" class="text-center name-initials-box">
                        <div class="pt-2">
                            {{game_broadcast_contact.broadcast_contact.name_initials}}
                        </div>
                    </b-col>
                    <b-col cols="9">
                        <div class="contact-name mt-3 ml-3">
                            {{game_broadcast_contact.broadcast_contact.fullname}}
                        </div>
                        <div class="contact-mobile mb-4 ml-3">
                            {{game_broadcast_contact.broadcast_contact.mobile_display}}
                        </div>
                    </b-col>
                </b-row>
             </div>
        </div>
        <div v-if="!show_fans_watching">
            <div v-if="show_loading_game && !show_game_on_page" class="text-center margin-top-10">
            <i class="fad fa-circle-notch fa-spin fast-spin green-text fa-10x"></i>
            <div class="title-label green-text text-center mt-5">
                Loading Game..
            </div>
        </div>
            <div v-if="show_game_on_page && !show_loading_game" class="text-center margin-top-10">
            <div class="large-text green-text text-center mt-2">
                Game On!
            </div>
        </div>
            <div v-if="game.type === 0 && !show_loading_game && !show_game_on_page">
            <div>
                <i class="fal fa-chevron-left fa-4x light-grey-text mt-3 ml-4" @click="cancel_game()"></i>
                <hr>
            </div>
            <div class="title-label text-center margin-top-7">
                Which game would you like to play?
            </div>
            <div class="text-center mr-5 ml-5 margin-top-7">
                <b-button class="dash-btn" variant="light" size="lg" @click="game.type=1">Putt vs The Pro</b-button>
            </div>
            <div class="text-center mr-5 ml-5 mt-5">
                <b-button class="dash-btn" variant="light" size="lg" @click="game.type=2">Up & Downs</b-button>
            </div>
        </div>
            <div v-if="game.game_holes === 0 && !show_loading_game && !show_game_on_page && game.type > 0">
            <div>
                <i class="fal fa-chevron-left fa-4x light-grey-text mt-3 ml-4" @click="game.type=0"></i>
                <hr>
            </div>
                <div class="title-label text-center margin-top-6">
                    How many holes would you like to play?
                </div>
                <div class="ml-5 mr-5 mt-5">
                    <b-form-select id="game_holes_dropdown" v-model="selected_game_holes" :options="gameHoleOptions" ></b-form-select>
                </div>
                <div v-if="game.type === 1" class="title-label text-center margin-top-6">
                    Who would you like to play against?
                </div>
                <div v-if="game.type === 1"  class="ml-5 mr-5 mt-5">
                    <b-form-select id="opponent_dropdown" v-model="selected_opponent_token" :options="opponentOptions" ></b-form-select>
                </div>

            <div v-if="game.type === 2" class="title-label text-center margin-top-7">
                Maximum "Up & Down" yardage?
            </div>
            <div v-if="game.type === 2"  class="ml-5 mr-5 mt-5">
                <b-form-select id="updown_yardage_dropdown" v-model="selected_updown_yardage" :options="updownYardageOptions" :disabled="selected_game_holes === 0"></b-form-select>
            </div>

            <div v-if="game.type === 2" class="title-label text-center margin-top-7">
                Include greenside bunkers?
            </div>
            <div v-if="game.type === 2"  class="ml-5 mr-5 mt-5">
                <b-form-select id="game_holes_dropdown" v-model="selected_include_bunker" :options="includeBunkerOptions" :disabled="selected_updown_yardage === 0"></b-form-select>
            </div>

            <div class="text-center mr-5 ml-5 margin-top-6">
                <b-button class="start-game-btn" variant="success" size="lg" :disabled="disable_start_btn" @click="start_game()">Start Game</b-button>
            </div>
            <div class="title-label text-center margin-top-10">
                <i class="fad fa-signal-stream green-text mr-3"></i> Broadcast this game!
            </div>
            <div v-if="broadcast_count > 0" class="text-center sub-label-small">
                <strong>({{broadcast_count}})</strong> fans will receive a text message to watch this round.
            </div>
            <div  v-if="broadcast_count === 0" class="text-center mr-5 ml-5 mt-5">
                <b-button class="add-fans-btn" variant="outline-success" size="lg" @click="go_fans()">Add Fans</b-button>
            </div>
            <div v-for="broadcast_contact in broadcast_contact_list" class="ml-5 mr-5 border mb-4 mt-5" @click="toggleBroadcast(broadcast_contact)">
                <b-row>
                    <b-col cols="2" class="text-center">
                        <div class="cursor-pointer">
                            <i v-if="broadcast_contact.broadcast" class="fad fa-signal-stream fa-3x green-text margin-top-6 ml-4"></i>
                            <i v-else class="fad fa-signal-stream fa-3x light-grey-text margin-top-6 ml-4"></i>
                        </div>
                    </b-col>
                    <b-col cols="10">
                        <div v-if="broadcast_contact.broadcast" class="contact-name-green mt-3">
                            <span>{{broadcast_contact.fullname}}</span>
                        </div>
                        <div v-else class="contact-name mt-3">
                            <span>{{broadcast_contact.fullname}}</span>
                        </div>
                        <div v-if="broadcast_contact.broadcast" class="contact-mobile-green mb-4">
                            {{broadcast_contact.mobile_display}}
                        </div>
                        <div v-else class="contact-mobile mb-4">
                            {{broadcast_contact.mobile_display}}
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>
            <div v-if="game.game_holes > 0 && !show_loading_game && !show_game_on_page">
            <b-row class="ml-3  mr-3 mt-3">
                <b-col cols="3">
                    <i class="fal fa-chevron-left fa-4x light-grey-text mt-3" @click="cancel_game()"></i>
                </b-col>
                <b-col cols="6" class="title-label text-center">
                    <div class="mt-2">You vs {{game.opponent.fullname}}</div>
                    <div v-if="game.type === 1" class="h3">Putting Match Play</div>
                    <div v-if="game.type === 2" class="h3">Up & Down Match Play</div>
                    <div class="h3">{{formatDate(game.start)}}</div>
                </b-col>
                <b-col cols="3">
                    <div class="float-right" @click="go_show_fans_watching()">
                        <i class="fad fa-users fa-4x light-grey-text mt-3"></i>
                        <b-badge v-if="fans_watching_list.length > 0" pill variant="primary" class="badge-font-size">{{fans_watching_list.length}}</b-badge>
                    </div>
                </b-col>
            </b-row>

            <b-row class="ml-3 mr-3 mt-5 text-center">
                <b-col class="border scorecard-cell" :class="{'background-blue':current_hole === 1,'background-green':current_hole > 1 && game.score_1 > 0,'background-red':current_hole > 1 && game.score_1 < 0}">
                    <span v-if="current_hole > 1" class="scorecard-font-size">
                        <span v-if="game.score_1 > 0">{{game.score_1}}<i class="fas fa-caret-up"></i></span>
                        <span v-else-if="game.score_1 < 0">{{convert_to_pos_number(game.score_1)}}<i class="fas fa-caret-down"></i></span>
                        <span v-else>AS</span>
                    </span>
                    <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 1 }">1</span>
                </b-col>
                <b-col class="border-top border-bottom scorecard-cell" :class="{'background-blue':current_hole === 2,'background-green':current_hole > 2 && game.score_2 > 0,'background-red':current_hole > 2 && game.score_2 < 0}">
                    <span v-if="current_hole > 2" class="scorecard-font-size">
                        <span v-if="game.score_2 > 0">{{game.score_2}}<i class="fas fa-caret-up"></i></span>
                        <span v-else-if="game.score_2 < 0">{{convert_to_pos_number(game.score_2)}}<i class="fas fa-caret-down"></i></span>
                        <span v-else class="dark-grey-text">AS</span>
                    </span>
                    <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 2 }">2</span>
                </b-col>
                <b-col class="border-top border-bottom scorecard-cell" :class="{'background-blue':current_hole === 3,'background-green':current_hole > 3 && game.score_3 > 0,'background-red':current_hole > 3 && game.score_3 < 0}">
                    <span v-if="current_hole > 3" class="scorecard-font-size">
                        <span v-if="game.score_3 > 0">{{game.score_3}}<i class="fas fa-caret-up"></i></span>
                        <span v-else-if="game.score_3 < 0">{{convert_to_pos_number(game.score_3)}}<i class="fas fa-caret-down"></i></span>
                        <span v-else>AS</span>
                    </span>
                    <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 3 }">3</span>
                </b-col>
                <b-col class="border scorecard-cell" :class="{'background-blue':current_hole === 4,'background-green':current_hole > 4 && game.score_4 > 0,'background-red':current_hole > 4 && game.score_4 < 0}">
                    <span v-if="current_hole > 4" class="scorecard-font-size">
                        <span v-if="game.score_4 > 0">{{game.score_4}}<i class="fas fa-caret-up"></i></span>
                        <span v-else-if="game.score_4 < 0">{{convert_to_pos_number(game.score_4)}}<i class="fas fa-caret-down"></i></span>
                        <span v-else>AS</span>
                    </span>
                    <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 4 }">4</span>
                </b-col>
                <b-col class="border-top border-bottom scorecard-cell" :class="{'background-blue':current_hole === 5,'background-green':current_hole > 5 && game.score_5 > 0,'background-red':current_hole > 5 && game.score_5 < 0}">
                    <span v-if="current_hole > 5" class="scorecard-font-size">
                        <span v-if="game.score_5 > 0">{{game.score_5}}<i class="fas fa-caret-up"></i></span>
                        <span v-else-if="game.score_5 < 0">{{convert_to_pos_number(game.score_5)}}<i class="fas fa-caret-down"></i></span>
                        <span v-else>AS</span>
                    </span>
                    <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 5 }">5</span>
                </b-col>
                <b-col class="border scorecard-cell" :class="{'background-blue':current_hole === 6,'background-green':current_hole > 6 && game.score_6 > 0,'background-red':current_hole > 6 && game.score_6 < 0}">
                    <span v-if="current_hole > 6" class="scorecard-font-size">
                        <span v-if="game.score_6 > 0">{{game.score_6}}<i class="fas fa-caret-up"></i></span>
                        <span v-else-if="game.score_6 < 0">{{convert_to_pos_number(game.score_6)}}<i class="fas fa-caret-down"></i></span>
                        <span v-else>AS</span>
                    </span>
                    <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 6 }">6</span>
                </b-col>
                <b-col class="border-top border-bottom scorecard-cell" :class="{'background-blue':current_hole === 7,'background-green':current_hole > 7 && game.score_7 > 0,'background-red':current_hole > 7 && game.score_7 < 0}">
                    <span v-if="current_hole > 7" class="scorecard-font-size">
                        <span v-if="game.score_7 > 0">{{game.score_7}}<i class="fas fa-caret-up"></i></span>
                        <span v-else-if="game.score_7 < 0">{{convert_to_pos_number(game.score_7)}}<i class="fas fa-caret-down"></i></span>
                        <span v-else>AS</span>
                    </span>
                    <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 7 }">7</span>
                </b-col>
                <b-col class="border scorecard-cell" :class="{'background-blue':current_hole === 8,'background-green':current_hole > 8 && game.score_8 > 0,'background-red':current_hole > 8 && game.score_8 < 0}">
                    <span v-if="current_hole > 8" class="scorecard-font-size">
                        <span v-if="game.score_8 > 0">{{game.score_8}}<i class="fas fa-caret-up"></i></span>
                        <span v-else-if="game.score_8 < 0">{{convert_to_pos_number(game.score_8)}}<i class="fas fa-caret-down"></i></span>
                        <span v-else>AS</span>
                    </span>
                    <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 8 }">8</span>
                </b-col>
                <b-col class="border-top border-bottom border-right scorecard-cell" :class="{'background-blue':current_hole === 9,'background-green':current_hole > 9 && game.score_9 > 0,'background-red':current_hole > 9 && game.score_9 < 0}">
                    <span v-if="current_hole > 9" class="scorecard-font-size">
                        <span v-if="game.score_9 > 0">{{game.score_9}}<i class="fas fa-caret-up"></i></span>
                        <span v-else-if="game.score_9 < 0">{{convert_to_pos_number(game.score_9)}}<i class="fas fa-caret-down"></i></span>
                        <span v-else>AS</span>
                    </span>
                    <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 9 }">9</span>
                </b-col>
            </b-row>

            <b-row v-if="game.game_holes === 18" class="ml-3 mr-3 text-center">
                <b-col class="border scorecard-cell" :class="{'background-blue':current_hole === 10,'background-green':current_hole > 10 && game.score_10 > 0,'background-red':current_hole > 10 && game.score_10 < 0}">
                    <span v-if="current_hole > 10" class="scorecard-font-size">
                        <span v-if="game.score_10 > 0">{{game.score_10}}<i class="fas fa-caret-up"></i></span>
                        <span v-else-if="game.score_10 < 0">{{convert_to_pos_number(game.score_10)}}<i class="fas fa-caret-down"></i></span>
                        <span v-else>AS</span>
                    </span>
                    <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 10 }">10</span>
                </b-col>
                <b-col class="border-top border-bottom scorecard-cell" :class="{'background-blue':current_hole === 11,'background-green':current_hole > 11 && game.score_11 > 0,'background-red':current_hole > 11 && game.score_11 < 0}">
                    <span v-if="current_hole > 11" class="scorecard-font-size">
                        <span v-if="game.score_11 > 0">{{game.score_11}}<i class="fas fa-caret-up"></i></span>
                        <span v-else-if="game.score_11 < 0">{{convert_to_pos_number(game.score_11)}}<i class="fas fa-caret-down"></i></span>
                        <span v-else class="dark-grey-text">AS</span>
                    </span>
                    <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 11 }">11</span>
                </b-col>
                <b-col class="border-top border-bottom scorecard-cell" :class="{'background-blue':current_hole === 12,'background-green':current_hole > 12 && game.score_12 > 0,'background-red':current_hole > 12 && game.score_12 < 0}">
                    <span v-if="current_hole > 12" class="scorecard-font-size">
                        <span v-if="game.score_12 > 0">{{game.score_12}}<i class="fas fa-caret-up"></i></span>
                        <span v-else-if="game.score_12 < 0">{{convert_to_pos_number(game.score_12)}}<i class="fas fa-caret-down"></i></span>
                        <span v-else>AS</span>
                    </span>
                    <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 12 }">12</span>
                </b-col>
                <b-col class="border scorecard-cell" :class="{'background-blue':current_hole === 13,'background-green':current_hole > 13 && game.score_13 > 0,'background-red':current_hole > 13 && game.score_13 < 0}">
                    <span v-if="current_hole > 13" class="scorecard-font-size">
                        <span v-if="game.score_13 > 0">{{game.score_13}}<i class="fas fa-caret-up"></i></span>
                        <span v-else-if="game.score_13 < 0">{{convert_to_pos_number(game.score_13)}}<i class="fas fa-caret-down"></i></span>
                        <span v-else>AS</span>
                    </span>
                    <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 13 }">13</span>
                </b-col>
                <b-col class="border-top border-bottom scorecard-cell" :class="{'background-blue':current_hole === 14,'background-green':current_hole > 14 && game.score_14 > 0,'background-red':current_hole > 14 && game.score_14 < 0}">
                    <span v-if="current_hole > 14" class="scorecard-font-size">
                        <span v-if="game.score_14 > 0">{{game.score_14}}<i class="fas fa-caret-up"></i></span>
                        <span v-else-if="game.score_14 < 0">{{convert_to_pos_number(game.score_14)}}<i class="fas fa-caret-down"></i></span>
                        <span v-else>AS</span>
                    </span>
                    <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 14 }">14</span>
                </b-col>
                <b-col class="border scorecard-cell" :class="{'background-blue':current_hole === 15,'background-green':current_hole > 15 && game.score_15 > 0,'background-red':current_hole > 15 && game.score_15 < 0}">
                    <span v-if="current_hole > 15" class="scorecard-font-size">
                        <span v-if="game.score_15 > 0">{{game.score_15}}<i class="fas fa-caret-up"></i></span>
                        <span v-else-if="game.score_15 < 0">{{convert_to_pos_number(game.score_15)}}<i class="fas fa-caret-down"></i></span>
                        <span v-else>AS</span>
                    </span>
                    <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 15 }">15</span>
                </b-col>
                <b-col class="border-top border-bottom scorecard-cell" :class="{'background-blue':current_hole === 16,'background-green':current_hole > 16 && game.score_16 > 0,'background-red':current_hole > 16 && game.score_16 < 0}">
                    <span v-if="current_hole > 16" class="scorecard-font-size">
                        <span v-if="game.score_16 > 0">{{game.score_16}}<i class="fas fa-caret-up"></i></span>
                        <span v-else-if="game.score_16 < 0">{{convert_to_pos_number(game.score_16)}}<i class="fas fa-caret-down"></i></span>
                        <span v-else>AS</span>
                    </span>
                    <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 16 }">16</span>
                </b-col>
                <b-col class="border scorecard-cell" :class="{'background-blue':current_hole === 17,'background-green':current_hole > 17 && game.score_17 > 0,'background-red':current_hole > 17 && game.score_17 < 0}">
                    <span v-if="current_hole > 17" class="scorecard-font-size">
                        <span v-if="game.score_17 > 0">{{game.score_17}}<i class="fas fa-caret-up"></i></span>
                        <span v-else-if="game.score_17 < 0">{{convert_to_pos_number(game.score_17)}}<i class="fas fa-caret-down"></i></span>
                        <span v-else>AS</span>
                    </span>
                    <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 17 }">17</span>
                </b-col>
                <b-col class="border-top border-bottom border-right scorecard-cell" :class="{'background-blue':current_hole === 18,'background-green':current_hole > 18 && game.score_18 > 0,'background-red':current_hole > 18 && game.score_18 < 0}">
                    <span v-if="current_hole > 18" class="scorecard-font-size">
                        <span v-if="game.score_18 > 0">{{game.score_18}}<i class="fas fa-caret-up"></i></span>
                        <span v-else-if="game.score_18 < 0">{{convert_to_pos_number(game.score_18)}}<i class="fas fa-caret-down"></i></span>
                        <span v-else>AS</span>
                    </span>
                    <span v-else class="scorecard-font-size" :class="{ 'light-grey-text': current_hole !== 18 }">18</span>
                </b-col>
            </b-row>

            <div v-if="!game.is_game_complete">
                <div class="title-label text-center margin-top-7">Hole {{current_hole}}</div>
                <div v-if="!loading_putt && game.type === 1" class="title-label text-center">{{game.putt_length}} Foot {{game.putt_slope_title}} Putt</div>
                <div v-if="!loading_putt && game.type === 2" class="title-label text-center">{{game.up_yardage_title}}</div>
                <div v-if="loading_putt" class="text-center"><i class="fad fa-circle-notch fa-spin fast-spin green-text fa-4x"></i></div>

                <div class="title-label text-center margin-top-7">{{game.opponent.fullname}}</div>
                <div v-if="!loading_opp_putt_num && game.type === 1" class="title-label text-center">{{game.pro_putt_number_current_hole}} Putted</div>
                <div v-if="!loading_opp_putt_num && game.type === 2 && game.pro_putt_number_current_hole === 2" class="title-label text-center">{{game.pro_putt_number_current_hole}} Strokes <i class="fas fa-check-circle green-text"></i> Up & Down!</div>
                <div v-if="!loading_opp_putt_num && game.type === 2 && game.pro_putt_number_current_hole > 2" class="title-label text-center">{{game.pro_putt_number_current_hole}} Strokes (1 shot & 2 putts)</div>
                <div v-if="loading_opp_putt_num" class="text-center"><i class="fad fa-circle-notch fa-spin fast-spin green-text fa-4x"></i></div>

                <div v-if="game.type === 1"  class="title-label text-center margin-top-7">How many putts did it take you?</div>
                <b-row v-if="game.type === 1" class="text-center ml-3 mr-3 mt-3">

                    <b-col v-if="saving_btn_1 & !saving_btn_2 & !saving_btn_3" class="putt-btn border"><i class="fad fa-circle-notch fa-spin fast-spin green-text mt-4"></i></b-col>
                    <b-col v-if="!saving_btn_1 & !saving_btn_2 & !saving_btn_3" class="putt-btn border" @click="save_putt(1)">1 Putt</b-col>
                    <b-col v-if="saving_btn_2 || saving_btn_3" class="putt-btn border light-grey-text">1 Putt</b-col>

                    <b-col v-if="!saving_btn_1 & saving_btn_2 & !saving_btn_3" class="putt-btn border ml-5 mr-5"><i class="fad fa-circle-notch fa-spin fast-spin green-text mt-4"></i></b-col>
                    <b-col v-if="!saving_btn_1 & !saving_btn_2 & !saving_btn_3" class="putt-btn border ml-5 mr-5" @click="save_putt(2)">2 Putts</b-col>
                    <b-col v-if="saving_btn_1 || saving_btn_3" class="putt-btn border ml-5 mr-5 light-grey-text">2 Putts</b-col>

                    <b-col v-if="!saving_btn_1 & !saving_btn_2 & saving_btn_3" class="putt-btn border"><i class="fad fa-circle-notch fa-spin fast-spin green-text mt-4"></i></b-col>
                    <b-col v-if="!saving_btn_1 & !saving_btn_2 & !saving_btn_3" class="putt-btn border" @click="save_putt(3)">3 Putts</b-col>
                    <b-col v-if="saving_btn_1 || saving_btn_2" class="putt-btn border light-grey-text">3 Putts</b-col>

                </b-row>

                <div v-if="game.type === 2"  class="title-label text-center margin-top-7">How many strokes did it take you?</div>
                <b-row v-if="game.type === 2" class="text-center ml-3 mr-3 mt-3">
                    <b-col v-if="saving_btn_1 & !saving_btn_2 & !saving_btn_3 & !saving_btn_4" class="putt-btn border mr-2"><i class="fad fa-circle-notch fa-spin fast-spin green-text mt-4"></i></b-col>
                    <b-col v-if="!saving_btn_1 & !saving_btn_2 & !saving_btn_3 & !saving_btn_4" class="putt-btn border mr-2" @click="save_ud_strokes(1)">1</b-col>
                    <b-col v-if="saving_btn_2 || saving_btn_3 || saving_btn_4" class="putt-btn border light-grey-text mr-2">1</b-col>

                    <b-col v-if="!saving_btn_1 & saving_btn_2 & !saving_btn_3 & !saving_btn_4" class="putt-btn border mr-2 ml-2"><i class="fad fa-circle-notch fa-spin fast-spin green-text mt-4"></i></b-col>
                    <b-col v-if="!saving_btn_1 & !saving_btn_2 & !saving_btn_3 & !saving_btn_4" class="putt-btn border mr-2 ml-2 green-text" @click="save_ud_strokes(2)">2</b-col>
                    <b-col v-if="saving_btn_1 || saving_btn_3 || saving_btn_4" class="putt-btn border light-grey-text mr-2 ml-2">2</b-col>

                    <b-col v-if="!saving_btn_1 & !saving_btn_2 & saving_btn_3 & !saving_btn_4" class="putt-btn border mr-2 ml-2"><i class="fad fa-circle-notch fa-spin fast-spin green-text mt-4"></i></b-col>
                    <b-col v-if="!saving_btn_1 & !saving_btn_2 & !saving_btn_3 & !saving_btn_4" class="putt-btn border mr-2 ml-2" @click="save_ud_strokes(3)">3</b-col>
                    <b-col v-if="saving_btn_1 || saving_btn_2 || saving_btn_4" class="putt-btn border light-grey-text mr-2 ml-2">3</b-col>

                    <b-col v-if="!saving_btn_1 & !saving_btn_2 & !saving_btn_3 & saving_btn_4" class="putt-btn border ml-2"><i class="fad fa-circle-notch fa-spin fast-spin green-text mt-4"></i></b-col>
                    <b-col v-if="!saving_btn_1 & !saving_btn_2 & !saving_btn_3 & !saving_btn_4" class="putt-btn border ml-2" @click="save_ud_strokes(4)">4+</b-col>
                    <b-col v-if="saving_btn_1 || saving_btn_2 || saving_btn_3" class="putt-btn border light-grey-text ml-2">4+</b-col>
                </b-row>



            </div>
            <div v-if="game.is_game_complete">
                <div class="title-label text-center mt-5 mb-5">{{game.score_text}}</div>
                <div v-for="hole in hole_summary_list" >
                    <b-row class="ml-3 mr-3 border mb-3">
                        <b-col cols="2" class="text-center">
                            <i v-if="hole.hole_score > 0" class="fas fa-caret-up fa-10x mt-3 green-text"></i>
                            <i v-else-if="hole.hole_score === 0" class="far fa-equals fa-5x margin-top-6 light-grey-text"></i>
                            <i v-else class="fas fa-caret-down fa-10x mt-3 red-text"></i>
                        </b-col>
                        <b-col cols="10">
                            <b-row class="h1"><strong>Hole {{hole.hole_number}}</strong></b-row>
                            <b-row class="h1">{{hole.hole_description}}</b-row>
                            <b-row v-if="game.type === 1" class="h1"><span :class="{'green-text': hole.opponent_strokes === 1}">{{game.opponent.fullname}}&nbsp;{{hole.opponent_strokes}}-Putted</span></b-row>
                            <b-row v-if="game.type === 1" class="h1"><span :class="{'green-text': hole.your_strokes === 1}">You&nbsp;{{hole.your_strokes}}-Putted</span></b-row>
                            <b-row v-if="game.type === 2" class="h1">Tiger {{hole.opponent_strokes}} Strokes <span v-if="hole.opponent_strokes === 2" class="ml-3 green-text"> <b-badge variant="success">Up & Down</b-badge></span></b-row>
                            <b-row v-if="game.type === 2" class="h1">You {{hole.your_strokes}} Strokes <span v-if="hole.your_strokes === 2" class="ml-3 green-text"> <b-badge variant="success">Up & Down</b-badge></span></b-row>
                        </b-col>
                    </b-row>
                </div>
            </div>

        </div>
        </div>
    </div>
</template>

<script>
    import _ from "underscore";

    export default {
        name: "Game",
        data() {
            return {
                GameRes: this.$resource('/api/game{/token}/'),
                BroadcastContactRes: this.$resource('/api/broadcastcontact{/token}/'),
                GameBroadcastContactRes: this.$resource('/api/gamebroadcastcontact{/token}/'),
                FriendRes: this.$resource('/api/friend{/token}/'),
                game: {},
                selected_game_holes: 0,
                gameHoleOptions: [
                    {value: 0, text: '-- select --'},
                    {value: 9, text: '9 Holes'},
                    {value: 18, text: '18 Holes'},
                ],
                selected_opponent_token: 0,
                opponentOptions: [
                    {value: 0, text: '-- select --'},
                    {value: 'sTyGwKwDtC', text: 'Tiger Woods (97)'},
                    {value: 'xxmC1v3m3b', text: 'Jordan Speith (95)'},
                    {value: 'QDg0TSB2T3', text: 'PGA Pro (90)'},
                ],
                selected_updown_yardage: 0,
                updownYardageOptions: [
                    {value: 0, text: '-- select --'},
                    {value: 10, text: '10 yards'},
                    {value: 20, text: '20 yards'},
                    {value: 30, text: '30 yards'},
                    {value: 40, text: '40 yards'},
                    {value: 50, text: '50 yards'},
                    {value: 60, text: '60 yards'},
                    {value: 70, text: '70 yards'},
                    {value: 80, text: '80 yards'},
                    {value: 90, text: '90 yards'},
                    {value: 100, text: '100 yards'},
                ],
                selected_include_bunker: 0,
                includeBunkerOptions: [
                    {value: 0, text: '-- select --'},
                    {value: 1, text: 'Yes'},
                    {value: 2, text: 'No'},
                ],

                show_loading_game: false,
                show_game_on_page: false,
                disable_start_btn: true,
                current_hole: 0,
                current_putt_length: 0,
                current_putt_slope: 'na',
                loading_putt: false,
                loading_opp_putt_num: false,
                saving_btn_1: false,
                saving_btn_2: false,
                saving_btn_3: false,
                saving_btn_4: false,
                hole_summary_list: [],
                broadcast_contact_list: [],
                broadcast_count: 0,
                fans_watching_list: [],
                show_fans_watching: false
            };
        },
        mounted() {
            let game_token = this.$route.params.token;
            this.GameRes.get({token: game_token}).then((resp) => {
                this.game = resp.body;
                this.current_hole = this.game.player_last_hole_recorded + 1;
                this.create_game_summary();
                this.BroadcastContactRes.get({player_token: this.game.player.token}).then((resp) => {
                    this.broadcast_contact_list = resp.body;
                    this.get_broadcast_count();
                    this.get_fans_watching();
                    this.get_friends();
                }, () => { console.error(arguments); });
            }, () => { console.error(arguments); });
        },
        methods: {
            testApi() {
                console.log('Ben Test update to API2');
                this.GameRes.update({token: 'qhcc7xCPRx'}, {'test': 'test1'}).then((resp) => {
                    console.log(resp.body);
                    }, () => {
                        console.error(arguments);
                    });
            },

            start_game() {
                if (this.selected_game_holes > 0) {
                    this.show_loading_game = true;
                    if (this.game.type === 2) {
                        var game_type = 'ud_game';
                        var ud_yardage = this.selected_updown_yardage;
                        var ud_include_bunker = this.selected_include_bunker;
                    } else {
                        var game_type = 'putting_game';
                        var ud_yardage = 'na';
                        var ud_include_bunker = 'na'
                    }
                    let data = {
                        save_type: 'start_game',
                        game_type: game_type,
                        game_token: this.game.token,
                        selected_game_holes: this.selected_game_holes,
                        selected_opponent_token: this.selected_opponent_token,
                        ud_yardage: ud_yardage,
                        ud_include_bunker: ud_include_bunker,
                    };
                    this.GameRes.save(data).then((resp) => {
                        this.game = resp.body;
                        this.current_hole = this.game.player_last_hole_recorded + 1;
                        setTimeout(() => {
                            this.show_loading_game = false;
                            this.show_game_on_page = true;
                            setTimeout(() => {
                                this.show_game_on_page = false;
                                this.get_fans_watching();
                            }, 1000);
                        }, 2000);

                    }, () => {
                        console.error(arguments);
                    });
                }
            },
            get_fans_watching() {
                this.GameBroadcastContactRes.get({game_token: this.game.token}).then((resp) => {
                    this.fans_watching_list = resp.body;
                }, () => { console.error(arguments); });
            },
            get_friends() {
                this.FriendRes.get({player_token: this.game.player.token}).then((resp) => {
                    for (var i = 0; i < resp.body.length; i++) {
                        let friend = resp.body[i];
                        let label = friend['following']['fullname'] +" ("+friend['following']['putting_score']+")"
                        console.log(friend);
                        let opponent_dict = {value: friend['following']['token'], text: label};
                        this.opponentOptions.push(opponent_dict);
                    }
                }, () => { console.error(arguments); });
            },
            cancel_game() {
                this.$router.push(`/dashboard/${this.game.player.token}/`);
            },
            convert_to_pos_number(negative_num) {
                return Math.abs(negative_num)
            },
            save_ud_strokes(num) {
                if (num === 1) {
                    this.saving_btn_1 = true;
                } else if (num === 2) {
                    this.saving_btn_2 = true;
                }  else if (num === 3) {
                    this.saving_btn_3 = true;
                }  else if (num === 4) {
                    this.saving_btn_4 = true;
                }
                let data = {
                    num_strokes: num,
                    game_token: this.game.token,
                    current_hole: this.current_hole,
                };
                this.GameRes.save(data).then((resp) => {
                    this.game = resp.body;
                    this.current_hole = this.game.player_last_hole_recorded + 1;
                    this.loading_putt = true;
                    this.loading_opp_putt_num = true;
                    this.create_game_summary();
                    setTimeout(() => {
                        this.loading_putt = false;
                        setTimeout(() => {
                            this.loading_opp_putt_num = false;
                            setTimeout(() => {
                                this.saving_btn_1 = false;
                                this.saving_btn_2 = false;
                                this.saving_btn_3 = false;
                                this.saving_btn_4 = false;
                            }, 500);
                        }, 500);
                    }, 500);
                }, () => {
                    console.error(arguments);
                });
            },
            save_putt(num) {
                this.get_fans_watching();
                if (num === 1) {
                    this.saving_btn_1 = true;
                } else if (num === 2) {
                    this.saving_btn_2 = true;
                }  else if (num === 3) {
                    this.saving_btn_3 = true;
                }
                console.log(num);
                let data = {
                    num_putts: num,
                    game_token: this.game.token,
                    current_hole: this.current_hole,
                };
                this.GameRes.save(data).then((resp) => {
                    this.game = resp.body;
                    this.current_hole = this.game.player_last_hole_recorded + 1;
                    this.loading_putt = true;
                    this.loading_opp_putt_num = true;
                    this.create_game_summary();
                    setTimeout(() => {
                        this.loading_putt = false;
                        setTimeout(() => {
                            this.loading_opp_putt_num = false;
                            setTimeout(() => {
                                this.saving_btn_1 = false;
                                this.saving_btn_2 = false;
                                this.saving_btn_3 = false;
                            }, 500);
                        }, 500);
                    }, 500);

                }, () => {
                    console.error(arguments);
                });
            },
            formatDate(d) {
                var options = { year: 'numeric', month: 'short', day: 'numeric' };
                var d2  = new Date(d);
                var formatted_date = d2.toLocaleDateString("en-US", options);
                var formatted_time = d2.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                return formatted_date + ' ' + formatted_time
            },
            slope_title (num) {
                if (num === 1) {
                    return 'Uphill'
                } else {
                    return 'Downhill'
                }
            },
             get_putt_description (num) {
                if (num === 1) {
                    return this.game.putt_length + " Foot " + this.slope_title (this.game.putt_slope_1) + " Putt "
                } else {
                    return "testing"
                }
            },
            create_game_summary () {
                this.hole_summary_list = []
                for (let hole = 1; hole <= this.game.player_last_hole_recorded; hole++) {
                    if (hole === 1) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_1 + " Foot " + this.slope_title (this.game.putt_slope_1) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_1 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_1 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_1,
                            your_strokes : this.game.player_1,
                            hole_score : this.game.pro_1 - this.game.player_1
                        };
                    } else if (hole === 2) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_2 + " Foot " + this.slope_title (this.game.putt_slope_2) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_2 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_2 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_2,
                            your_strokes : this.game.player_2,
                            hole_score : this.game.pro_2 - this.game.player_2
                        };
                    } else if (hole === 3) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_3 + " Foot " + this.slope_title (this.game.putt_slope_3) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_3 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_3 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_3,
                            your_strokes : this.game.player_3,
                            hole_score : this.game.pro_3 - this.game.player_3
                        };
                    }  else if (hole === 4) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_4 + " Foot " + this.slope_title (this.game.putt_slope_4) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_4 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_4 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_4,
                            your_strokes : this.game.player_4,
                            hole_score : this.game.pro_4 - this.game.player_4
                        };
                    }  else if (hole === 5) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_5 + " Foot " + this.slope_title (this.game.putt_slope_5) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_5 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_5 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_5,
                            your_strokes : this.game.player_5,
                            hole_score : this.game.pro_5 - this.game.player_5
                        };
                    }  else if (hole === 6) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_6 + " Foot " + this.slope_title (this.game.putt_slope_6) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_6 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_6 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_6,
                            your_strokes : this.game.player_6,
                            hole_score : this.game.pro_6 - this.game.player_6
                        };
                    }  else if (hole === 7) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_7 + " Foot " + this.slope_title (this.game.putt_slope_7) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_7 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_7 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_7,
                            your_strokes : this.game.player_7,
                            hole_score : this.game.pro_7 - this.game.player_7
                        };
                    }  else if (hole === 8) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_8 + " Foot " + this.slope_title (this.game.putt_slope_8) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_8 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_8 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_8,
                            your_strokes : this.game.player_8,
                            hole_score : this.game.pro_8 - this.game.player_8
                        };
                    }  else if (hole === 9) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_9 + " Foot " + this.slope_title (this.game.putt_slope_9) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_9 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_9 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_9,
                            your_strokes : this.game.player_9,
                            hole_score : this.game.pro_9 - this.game.player_9
                        };
                    }  else if (hole === 10) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_10 + " Foot " + this.slope_title (this.game.putt_slope_10) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_10 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_10 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_10,
                            your_strokes : this.game.player_10,
                            hole_score : this.game.pro_10 - this.game.player_10
                        };
                    }  else if (hole === 11) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_11 + " Foot " + this.slope_title (this.game.putt_slope_11) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_11 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_11 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_11,
                            your_strokes : this.game.player_11,
                            hole_score : this.game.pro_11 - this.game.player_11
                        };
                    }  else if (hole === 12) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_12 + " Foot " + this.slope_title (this.game.putt_slope_12) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_12 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_12 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_12,
                            your_strokes : this.game.player_12,
                            hole_score : this.game.pro_12 - this.game.player_12
                        };
                    }  else if (hole === 13) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_13 + " Foot " + this.slope_title (this.game.putt_slope_13) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_13 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_13 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_13,
                            your_strokes : this.game.player_13,
                            hole_score : this.game.pro_13 - this.game.player_13
                        };
                    }  else if (hole === 14) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_14 + " Foot " + this.slope_title (this.game.putt_slope_14) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_14 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_14 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_14,
                            your_strokes : this.game.player_14,
                            hole_score : this.game.pro_14 - this.game.player_14
                        };
                    }  else if (hole === 15) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_15 + " Foot " + this.slope_title (this.game.putt_slope_15) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_15 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_15 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_15,
                            your_strokes : this.game.player_15,
                            hole_score : this.game.pro_15 - this.game.player_15
                        };
                    }  else if (hole === 16) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_16 + " Foot " + this.slope_title (this.game.putt_slope_16) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_16 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_16 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_16,
                            your_strokes : this.game.player_16,
                            hole_score : this.game.pro_16 - this.game.player_16
                        };
                    }  else if (hole === 17) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_17 + " Foot " + this.slope_title (this.game.putt_slope_17) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_17 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_17 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_17,
                            your_strokes : this.game.player_17,
                            hole_score : this.game.pro_17 - this.game.player_17
                        };
                    }  else if (hole === 18) {
                        if (this.game.type === 1){
                            var hole_desc = this.game.putt_length_18 + " Foot " + this.slope_title (this.game.putt_slope_18) + " Putt ";
                        } else {
                            var hole_desc = this.game.ud_distance_18 === 0 ? "Green Side Bunker Shot" : this.game.ud_distance_18 + " Yard Pitch";
                        }
                        var hole_data = {
                            hole_number : hole,
                            hole_description : hole_desc,
                            opponent_strokes : this.game.pro_18,
                            your_strokes : this.game.player_18,
                            hole_score : this.game.pro_18 - this.game.player_18
                        };
                    }  else {
                        var hole_data = {
                            hole_number: hole,
                            hole_description: 'na',
                            opponent_strokes : 'na',
                            your_strokes : 'na',
                            hole_score : 'na'
                        };

                    }
                    this.hole_summary_list.push(hole_data);
                }
            },
            get_broadcast_count() {
                this.broadcast_count = this.broadcast_contact_list.filter((broadcast_contact) => broadcast_contact.broadcast === true).length;
            },
            toggleBroadcast(broadcast_contact) {
                broadcast_contact.broadcast = !broadcast_contact.broadcast;
                this.get_broadcast_count();
                let data = {
                    save_type: 'change_broadcast_status',
                    broadcast_status: broadcast_contact.broadcast,
                    broadcast_contact_id: broadcast_contact.id,
                };
                this.BroadcastContactRes.save(data).then((resp) => {
                    console.log(resp.body)
                }, () => {
                    console.error(arguments);
                });
            },
            go_fans() {
                this.$router.push(`/fans/${this.game.player.token}/`);
            },
            go_show_fans_watching() {
                this.show_fans_watching = true;
            },
            close_show_fans_watching() {
                this.show_fans_watching = false;
            },
        },
        watch: {
            selected_game_holes(n, o) {
                console.log(n);
                if (this.game.type === 1 && this.selected_game_holes > 0 && this.selected_opponent_token !== 0) {
                    this.disable_start_btn = false;
                } else this.disable_start_btn = !(this.game.type === 2 && this.selected_game_holes > 0);
            },
            selected_opponent_token(n, o) {
                console.log(n);
                if (this.game.type === 1 && this.selected_game_holes > 0 && this.selected_opponent_token !== 0) {
                    this.disable_start_btn = false;
                } else this.disable_start_btn = !(this.game.type === 2 && this.selected_game_holes > 0);
            }
        },
        computed: {
        },
    }
</script>