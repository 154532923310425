<style lang="less">
    .cursor-pointer {
        cursor: pointer;
    }
    .margin-top-10 {
        margin-top: 10rem !important;
    }
    .margin-top-7 {
        margin-top: 7rem !important;
    }
    .margin-top-65 {
        margin-top: 6rem !important;
    }
    .margin-top-6 {
        margin-top: 5rem !important;
    }
    .dash-btn {
        width: 100%;
        height: 8rem;
        font-size: 4rem;
    }
        .background-blue {
        background-color: #87cefa;
        color: white;
    }
    .background-green {
        background-color: forestgreen;
        color: white;
    }
    .background-red {
        background-color: red;
        color: white;
    }
    .red-text {
        color: red;
    }
    .green-text {
        color: forestgreen;
    }
    .blue-text {
        color: blue;
    }
    .dark-grey-text {
        color: #333333;
    }
    .light-grey-text {
        color: #999999;
    }
    .title-text {
        color: #666666;
        font-size: 4rem;
    }
    .stats-table {
        font-size: 2rem;
    }
    .best-length-highlight {
        background-color: darkgreen;
        color: white;
        /*border-style: solid;*/
        /*border-color: darkgreen;*/
    }
    .worst-length-highlight {
        background-color: darkred;
        color: white;
        /*border-style: solid;*/
        /*border-color: darkred;*/
    }
    .contact-name {
        font-size: 4rem;
    }
    .contact-mobile {
        font-size: 3rem;
    }
    .new-contact-input {

        height: 8rem;
        font-size: 3rem;
    }
    .new-contact-btn {
        width: 100%;
        height: 8rem;
        font-size: 4rem;
    }
    .delete-keep-btn {
        width: 100%;
        height: 14rem;
        font-size: 3rem;
    }
    .highlight-bx {
        background-color: #ccffcc;
    }
</style>

<template>
    <div>
        <b-row>
            <b-col>
                <i  v-if="!showAddFanConfirm" class="fal fa-chevron-left fa-4x light-grey-text mt-3 ml-4" @click="go_dashboard()"></i>
            </b-col>
            <b-col class="text-center">
                <span class="title-text">Fans</span>
            </b-col>
            <b-col></b-col>
        </b-row>
        <hr>
        <div v-if="showAddFanConfirm">
            <div v-if="loadingFanConfirm" class="text-center margin-top-10">
                <i class="fad fa-circle-notch fa-spin fast-spin green-text fa-10x"></i>
            </div>
            <div v-if="!loadingFanConfirm" class="mt-5">
                <div class="title-text ml-5 mt-5 text-center">
                    Please confirm you would like to add the following fan.
                </div>
                <div class="title-text ml-5 mt-5 text-center">
                    Name: <strong>{{add_fan_name}}</strong> <br>
                    Mobile: <strong>{{add_fan_mobile}}</strong>
                </div>
                <b-row class="mr-5 ml-5 margin-top-7">
                    <b-col>
                        <b-button variant="light" class="new-contact-btn" @click="showAddFanConfirm=false">Cancel</b-button>
                    </b-col>
                    <b-col>
                        <b-button variant="success" class="new-contact-btn" @click="addFan()">Add Fan</b-button>
                    </b-col>
                </b-row>
            </div>
        </div>
        <div v-if="!showAddFanConfirm">
            <div class="title-text ml-5 mt-5">
                <i class="fad fa-user-plus"></i> Fan
            </div>
            <div class="ml-5 mr-5 mt-5">
                <b-form-input v-model="add_fan_name" placeholder="Fan's fullname" class="new-contact-input pl-5 pr-5" autofocus></b-form-input>
            </div>
            <div class="ml-5 mr-5 mt-4">
                <b-form-input v-model="add_fan_mobile" placeholder="Fan's mobile #" class="new-contact-input pl-5 pr-5" @keyup="formatPhone()"></b-form-input>
            </div>

            <div class="ml-5 mr-5">
                <b-button variant="success" class="new-contact-btn mt-5" :disabled="add_fan_name === '' || invalid_mobile" @click="goAddFanConfirm">Add Fan</b-button>
            </div>
            <div class="title-text ml-5 margin-top-7">
                <i class="fad fa-users"></i> Fans ({{broadcast_contact_list.length}})
            </div>
            <div v-for="broadcast_contact in broadcast_contact_list" class="ml-5 mr-5 border mb-4 mt-5" :class="{ 'highlight-bx': broadcast_contact.id === highlightBroadcastContactId}">
                <b-row v-if="broadcast_contact.id === removeBroadcastContact.id">
                    <b-col cols="3" class="text-center">
                            <b-button class="delete-keep-btn" @click="keepFan()">Keep</b-button>
                    </b-col>
                    <b-col cols="3" class="text-center">
                            <b-button variant="danger" class="delete-keep-btn" @click="removeFan(broadcast_contact)"><i class="far fa-trash"></i></b-button>
                    </b-col>
                    <b-col cols="6">
                        <div class="contact-name mt-3">
                            {{broadcast_contact.fullname}}
                        </div>
                        <div class="contact-mobile mb-4">
                            {{broadcast_contact.mobile_display}}
                        </div>
                    </b-col>
                </b-row>
                <b-row v-else>
                    <b-col cols="2" class="text-center">
                        <div @click="removeFanConfirm(broadcast_contact)" class="cursor-pointer">
                            <i class="fal fa-times-circle fa-3x light-grey-text margin-top-6 ml-4"></i>
                        </div>
                    </b-col>
                    <b-col cols="10">
                        <div class="contact-name mt-3">
                            {{broadcast_contact.fullname}}
                        </div>
                        <div class="contact-mobile mb-4">
                            {{broadcast_contact.mobile_display}}
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "Fans",
        data() {
            return {
                PlayerRes: this.$resource('/api/player{/token}/'),
                player_token: '',
                BroadcastContactRes: this.$resource('/api/broadcastcontact{/token}/'),
                broadcast_contact_list: [],
                add_fan_name: '',
                add_fan_mobile: '',
                invalid_mobile: true,
                showAddFanConfirm: false,
                loadingFanConfirm: true,
                removeBroadcastContact: {},
                highlightBroadcastContactId: 0,
            };
        },
        mounted() {
            this.player_token = this.$route.params.token;
            this.BroadcastContactRes.get({player_token: this.player_token}).then((resp) => {
                this.broadcast_contact_list = resp.body;
                console.log(this.broadcast_contact_list);
            }, () => { console.error(arguments); });
        },
        methods: {
            go_dashboard() {
                this.$router.push(`/dashboard/${this.player_token}/`);
            },
            formatPhone() {
                let first_char = this.add_fan_mobile.charAt(0);
                let last_char = this.add_fan_mobile.charAt(this.add_fan_mobile.length-1);
                let regExp = /[a-zA-Z]/g;
                if (regExp.test(this.add_fan_mobile)){
                    this.add_fan_mobile = this.add_fan_mobile.substring(0, this.add_fan_mobile.length-1)
                    return
                }
                if(this.add_fan_mobile.length > 13 && first_char === "(" ) {
                    let cleaned = this.add_fan_mobile.replace(/\D/g, '');
                    this.add_fan_mobile = "("+cleaned.substring(0, 3)+")"+cleaned.substring(3, 6)+"-"+cleaned.substring(6, 10);
                }
                if (this.add_fan_mobile.length === 9 && first_char === "(" ) {
                    let cleaned = this.add_fan_mobile.replace(/\D/g, '');
                    this.add_fan_mobile = "("+cleaned.substring(0, 3)+")"+cleaned.substring(3, 6)+"-"+cleaned.substring(6, 7);
                }
                if (this.add_fan_mobile.length === 3 && first_char !== "(" ) {
                    this.add_fan_mobile = "("+this.add_fan_mobile+")";
                }
                if(this.add_fan_mobile.length > 12 && first_char === "(" ) {
                    this.invalid_mobile = false;
                } else {
                    this.invalid_mobile = true;
                }
                return this.add_fan_mobile;
            },
            goAddFanConfirm() {
                this.loadingFanConfirm = true;
                this.showAddFanConfirm = true;
                setTimeout(() => {
                    this.loadingFanConfirm = false;
                }, 500);
            },
            addFan() {
                this.loadingFanConfirm = true;
                let data = {
                    save_type: 'save_new_broadcast_contact',
                    broadcast_contact_mobile: this.add_fan_mobile,
                    broadcast_contact_fullname: this.add_fan_name,
                    player_token: this.player_token,
                };
                this.BroadcastContactRes.save(data).then((resp) => {
                    console.log(resp);
                    setTimeout(() => {
                        this.add_fan_name = '';
                        this.add_fan_mobile = '';
                        this.showAddFanConfirm = false;
                        this.broadcast_contact_list.unshift(resp.body);
                        this.highlightBroadcastContactId = resp.body.id;
                        this.fadeOutHightlight();
                    }, 500);
                }, () => {
                    console.error(arguments);
                });
            },
            fadeOutHightlight() {
                setTimeout(() => {
                    this.highlightBroadcastContactId = 0;
                }, 2000);
            },
            removeFanConfirm(broadcast_contact) {
                console.log('Show Remove Fan Confirm');
                this.removeBroadcastContact = broadcast_contact;
            },
            keepFan() {
               this.removeBroadcastContact = {};
            },
            removeFan(broadcast_contact) {
                let data = {
                    save_type: 'deactivate_broadcast_contact',
                    broadcast_contact_id: broadcast_contact.id,
                };
                this.BroadcastContactRes.save(data).then((resp) => {
                    let index = this.broadcast_contact_list.indexOf(broadcast_contact);
                    this.broadcast_contact_list.splice(index, 1);
                    this.removeBroadcastContact = {};
                }, () => {
                    console.error(arguments);
                });

            }

        },
        watch: {
        },
        computed: {
        },

    }
</script>
