<style lang="less">
    .cursor-pointer {
        cursor: pointer;
    }
    .margin-top-10 {
        margin-top: 10rem !important;
    }
    .margin-top-7 {
        margin-top: 7rem !important;
    }
    .margin-top-6 {
        margin-top: 5rem !important;
    }
    .dash-btn {
        width: 100%;
        height: 8rem;
        font-size: 4rem;
    }
        .background-blue {
        background-color: #87cefa;
        color: white;
    }
    .background-green {
        background-color: forestgreen;
        color: white;
    }
    .background-red {
        background-color: red;
        color: white;
    }
    .red-text {
        color: red;
    }
    .green-text {
        color: forestgreen;
    }
    .blue-text {
        color: blue;
    }
    .dark-grey-text {
        color: #333333;
    }
    .light-grey-text {
        color: #999999;
    }
    .title-text {
        color: #666666;
        font-size: 4rem;
    }
    .stats-table {
        font-size: 2rem;
    }
    .best-length-highlight {
        background-color: darkgreen;
        color: white;
        /*border-style: solid;*/
        /*border-color: darkgreen;*/
    }
    .worst-length-highlight {
        background-color: darkred;
        color: white;
        /*border-style: solid;*/
        /*border-color: darkred;*/
    }
    .dash-btn {
        width: 100%;
        height: 8rem;
        font-size: 4rem;
    }

    .standing-dd {
        width: 100%;
        height: 8rem;
    }
    .btn-lg {
        font-size: 4rem;
    }
    .dropdown-menu {
        font-size: 4rem;
    }
    .standing-title-labels {
        font-size: 4rem;
    }
</style>

<template>
    <div>
        <b-row class="ml-3 mt-3">
            <b-col cols="1">
                <i class="fal fa-chevron-left fa-4x light-grey-text mt-3" @click="go_back_btn()"></i>
            </b-col>
            <b-col cols="10" class="title-label text-center">
                <div v-if="diplay==='menu'" class="mt-2">Select</div>
                <div v-if="diplay==='stats'" class="mt-2">Putting Stats</div>
                <div v-if="diplay==='standings'" class="mt-2">Standings</div>
                <div v-if="diplay==='leaderboard'" class="mt-2">Leaderboard</div>
                <div v-if="diplay==='achievements'" class="mt-2">Achievements</div>
            </b-col>
            <b-col cols="1"></b-col>
        </b-row>
        <div v-if="diplay==='menu'">
            <div class="text-center mr-5 ml-5 margin-top-7">
                <b-button class="dash-btn" variant="outline-primary" size="lg" @click="change_display('stats')">Putting Stats</b-button>
                <b-button class="dash-btn margin-top-6" variant="outline-primary" size="lg" @click="change_display('standings')">Standings</b-button>
                <b-button class="dash-btn margin-top-6" variant="outline-primary" size="lg" @click="change_display('leaderboard')">Leaderboard</b-button>
                <b-button class="dash-btn margin-top-6" variant="outline-primary" size="lg" @click="change_display('achievements')">Achievements</b-button>
            </div>

        </div>
        <div v-if="diplay==='stats'">
            <b-table ref="statsTable" class="stats-table mt-5" :items="putting_stats_list" :fields="statTableFields" :tbody-tr-class="rowClass"> striped bordered borderless hover>
                <template v-slot:cell(special_field_one_putt_percentage)="data">
                    <div v-if="data.value < 0" >{{convert_to_pos_number(data.value)}}%</div>
                    <div v-else>{{ data.value }}%</div>
                </template>
                <template v-slot:cell(special_field_pro_one_putt_percentage)="data">
                    <div v-if="data.value < 0" >{{convert_to_pos_number(data.value)}}%</div>
                    <div v-else>{{ data.value }}%</div>
                </template>
            </b-table>
        </div>
        <div v-if="diplay==='standings'" class="text-center">
            <div class="text-center mr-5 ml-5 margin-top-7">
              <b-dropdown id="standing-dropdown" :text="selected_standing_name" size="lg" class="standing-dd">
                <b-dropdown-item v-for="standing in standing_list" @click="standing_selected(standing)">
                    <span v-if="player_token !== standing.player1.token">You vs {{standing.player1.fullname}}</span>
                    <span v-else>You vs {{standing.player2.fullname}}</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <b-row class="ml-3 mt-3 margin-top-7">
                <b-col class="text-center">
                    <div class="standing-title-labels">Wins</div>
                    <div v-if="player_token === selected_standing.player1.token" class="standing-title-labels">{{selected_standing.player1_wins}}</div>
                    <div v-else class="standing-title-labels">{{selected_standing.player2_wins}}</div>
                </b-col>
                <b-col class="text-center">
                    <div class="standing-title-labels">Losses</div>
                    <div v-if="player_token === selected_standing.player1.token" class="standing-title-labels">{{selected_standing.player2_wins}}</div>
                    <div v-else class="standing-title-labels">{{selected_standing.player1_wins}}</div>
                </b-col>
                <b-col class="text-center">
                    <div class="standing-title-labels">Ties</div>
                    <div class="standing-title-labels">{{selected_standing.ties}}</div>
                </b-col>
            </b-row>
        </div>
        <div v-if="diplay==='leaderboard'" class="text-center">
            <h1 class="margin-top-10">Coming soon..</h1>
        </div>
        <div v-if="diplay==='achievements'" class="text-center">
            <h1 class="margin-top-10">Coming soon..</h1>
        </div>

    </div>
</template>

<script>
    export default {
        name: "Stats",
        data() {
            return {
                diplay: 'menu',
                value: 65,
                max: 100,
                PlayerRes: this.$resource('/api/player{/token}/'),
                PlayerPuttRes: this.$resource('/api/playerputt{/token}/'),
                StandingRes: this.$resource('/api/standing{/token}/'),
                player_token: '',
                game_token: 'na',
                fantoken: 'na',
                putting_stats_list: [],
                standing_list: [],
                selected_standing: {},
                selected_standing_name: '-- select --',
                statTableFields: [
                    {
                        key: 'length',
                        label: 'Ft.',
                        class: 'text-center',
                    },
                    {
                        key: 'special_field_one_putt_percentage',
                        label: "1-Putt %",
                        class: 'text-center',
                    },
                    // {
                    //     key: 'special_field_percentage_icon',
                    //     label: "",
                    //     class: 'text-center',
                    // },
                    {
                        key: 'special_field_pro_one_putt_percentage',
                        label: "Pro 1-Putt %",
                        class: 'text-center',
                    },
                    {
                        key: 'attempts',
                        label: "Attempts",
                        class: 'text-center',
                    },
                ]
            };
        },
        mounted() {
            let player_token = this.$route.params.token;
            console.log(player_token);
            this.game_token = this.$route.params.game;
            this.fantoken =  this.$route.params.fantoken;
            this.PlayerPuttRes.get({player_token: player_token}).then((resp) => {
                this.putting_stats_list = resp.body;
                // might need to get player object from list here
                this.player_token = player_token;
                this.StandingRes.get({player_token: player_token}).then((resp) => {
                    console.log(resp.body);
                    this.standing_list = resp.body;
                    this.selected_standing = resp.body[0];
                    this.selected_standing_name = this.get_standing_name(this.selected_standing);
                }, () => { console.error(arguments); });
            }, () => { console.error(arguments); });
        },
        methods: {
            go_back_btn() {
                if (this.fantoken === "na") {
                    if (this.diplay === 'menu') {
                        this.$router.push(`/dashboard/${this.player_token}/`);
                    } else {
                        this.diplay = 'menu';
                    }
                } else {
                    this.$router.push(`/broadcast/${this.game_token}/${this.fantoken}/`);
                }
            },
            change_display(d) {
                this.diplay = d;
            },
            convert_to_pos_number(negative_num) {
                return Math.abs(negative_num)
            },
            rowClass(item, type) {
                if (!item || type !== 'row') return
                console.log(item.special_field_percentage_icon);

                if (item.special_field_percentage_icon >= 0 && item.special_field_ranking === 1) {
                    return 'best-length-highlight'
                } else if (item.special_field_percentage_icon >= 0) {
                    return 'table-success'
                } else if (item.special_field_percentage_icon < 0 && item.special_field_ranking === 99) {
                    return 'worst-length-highlight'
                } else {
                    return
                }

                // if (item.special_field_ranking === 1) return 'table-success'
                // if (item.special_field_ranking === 99) return 'table-danger'
            },
            get_standing_name(standing) {
                if (!this.player_token) {return}
                if (!standing) {return}
                if (this.player_token !== standing.player1.token) {
                    return 'You vs '+ standing.player1.fullname
                } else {
                    return 'You vs '+ standing.player2.fullname
                }
            },
            standing_selected(standing) {
                console.log('Standing Selected!')
                console.log(standing)
                this.selected_standing = standing;
                this.selected_standing_name = this.get_standing_name(standing);
            }
        },
        watch: {
        },
        computed: {
        },

    }
</script>
